import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import useDebounce from 'src/hooks/useDebounce';
import Project from 'src/models/Project';
import LoadingScreen from 'src/components/LoadingScreen';
import {
  getProjectByProjectId,
  getProjectsBySearchValue
} from 'src/apis/project';
import { submitError } from 'src/components/Forms/FormikHelper';

interface Props {
  [key: string]: any;
  projectId?: number;
}

const ProjectAutoComplete: FC<Props> = ({
  errors,
  touched,
  onBlur,
  onChange,
  disabled,
  required,
  filterOptions = (x) => x,
  extraQuery,
  projectId = 0
}) => {
  const [debouncedInputValue, inputValue, setInputValue] = useDebounce<string>(
    '',
    500
  );

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<{ project: Project }>({ project: null });
  const [options, setOptions] = useState<Project[]>([]);

  const search = async (value) => {
    if (!value || value === '') return;

    setIsLoading(true);
    let projects = [];

    try {
      projects = await getProjectsBySearchValue(value, extraQuery);
    } catch (err) {
      submitError(err, null, true);
    }
    if (projects.length !== 0) setOptions(projects);
    setIsLoading(false);
  };

  const retrieveExistingValueById = useCallback(async (id: number) => {
    setIsLoading(true);
    const project = await getProjectByProjectId(id);
    if (project) {
      setOptions([project]);
      setState({ project });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (
      state.project &&
      state.project.projectID &&
      options.length === 0 &&
      !isLoading
    ) {
      retrieveExistingValueById(state.project.projectID);
    }
  }, [state, options, retrieveExistingValueById]);

  useEffect(() => {
    if (debouncedInputValue.length > 2) {
      search(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    // check for values that the back-end will throw an error and ignore
    if (!projectId || projectId === 0 || state.project) return;

    setState({
      project: new Project({ projectID: projectId })
    });
  }, [projectId, state]);

  return (
    <Autocomplete
      disabled={disabled}
      id="projects"
      filterOptions={filterOptions}
      autoComplete
      fullWidth
      options={options}
      value={state.project}
      loading={isLoading}
      getOptionSelected={(option, value) =>
        option.projectID === value.projectID
      }
      getOptionLabel={(option) =>
        option.projectName
          ? `${option?.bvProjectNumber} / ${option.projectName} / ${option.projectID}`
          : ''
      }
      renderOption={(option) => (
        <>
          {option?.bvProjectNumber} / {option.projectName} / {option.projectID}
        </>
      )}
      onBlur={onBlur}
      onChange={(e: object, project: Project | null) => {
        setState({ project });
        onChange(e, project ? project : new Project());
      }}
      onInputChange={(event, newValue) => {
        setInputValue(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label="BV Project # / Project Name / Project ID"
          name="projectName"
          variant="outlined"
          error={Boolean(touched?.projectName && errors?.projectName)}
          helperText={touched?.projectName && errors?.projectName}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <LoadingScreen style={{ width: 20 }} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export default ProjectAutoComplete;
