import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const Lottie = ({
  data,
  onComplete = () => {},
  loop = true,
  autoplay = true,
  style
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;
    const anim = lottie.loadAnimation({
      // @ts-ignore
      container: ref.current,
      animationData: data,
      renderer: 'svg',
      loop,
      autoplay
    });

    anim.addEventListener('complete', onComplete);

    return () => {
      anim.removeEventListener('complete', onComplete);
      anim.stop();
      anim.destroy();
    };
  }, [ref]);

  return <div ref={ref} style={style} />;
};

export default Lottie;
