import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import fileSize from 'src/utils/fileSize';

const FileSizeFormatter = ({ value }) => fileSize(value, true);

const FileSizeTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={FileSizeFormatter} {...props} />
);

export { FileSizeTypeProvider, FileSizeFormatter };
