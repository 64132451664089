import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  Divider,
  Switch
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { useDispatch } from 'react-redux';
import useSettings from 'src/hooks/useSettings';
import { updateMyUserAccount } from 'src/slices/user';
import { submitError } from 'src/components/Forms/FormikHelper';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const Account: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const { user, logout } = useAuth();
  const [isOpen, setOpen] = useState<boolean>(false);
  const { settings, saveSettings } = useSettings();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      history.push('/');
    } catch (err) {
      submitError(err);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src={user?.avatar} />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {user?.userName}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem>
          Simple View
          <Switch
            checked={user?.simplifiedView}
            color="primary"
            onChange={() => {
              user.simplifiedView = !user.simplifiedView;
              dispatch(updateMyUserAccount(user.serialize()));
              history.push('/app/home');
              handleClose();
            }}
            name="simple-mode"
            inputProps={{ 'aria-label': 'Simple View' }}
          />
        </MenuItem>
        <Divider />
        {!user?.simplifiedView && (
          <>
            <MenuItem
              onClick={handleClose}
              component={RouterLink}
              to={`/app/users/edit/${user?.userID}`}
            >
              Profile
            </MenuItem>
            <Divider />
          </>
        )}
        <MenuItem onClick={handleClose} component={RouterLink} to={`/terms`}>
          Terms &amp; Conditions
        </MenuItem>
        <MenuItem onClick={handleClose} component={RouterLink} to={`/privacy`}>
          Privacy Policy
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
