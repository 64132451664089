import { Levels } from 'src/types/Levels';

const config = {
  clients: Levels.CLIENT,
  projects: Levels.PROJECT,
  programs: Levels.PROGRAM,
  sites: Levels.SITE,
  files: Levels.DOCUMENT,
  users: Levels.USER,
  home: 'dashboard'
};

export const match = (input, obj) => {
  var matched = Object.keys(obj).find(
    (key) => input.toLowerCase().search(key) > -1
  );
  return obj[matched] || null;
};

export const getCurrentLevelFromPath = (path: string) => {
  return match(path, config);
};
