import React, { useMemo } from 'react';
import type { FC } from 'react';
import logo from 'src/assets/bv-logo-black.svg';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  return <img alt="Logo" src={logo} width={100} {...props} />;
};

export default Logo;
