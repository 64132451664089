import React, { useEffect, useState, useCallback } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputMask from 'react-input-mask';
import { IUser } from 'src/types/IUser';
import isBvEmail from 'src/utils/isBvEmail';

import {
  Button,
  Box,
  TextField,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
  Grid
} from '@material-ui/core';
import {
  phoneRegEx,
  validationMessage,
  passwordRegEx
} from 'src/utils/validations';

import axios from 'axios';
import urlBuilder from 'src/utils/urlBuilder';
import { Alert } from '@material-ui/lab';
import ErrorPanel from 'src/components/Forms/ErrorPanel';
import { submitError, submitSuccess } from 'src/components/Forms/FormikHelper';
import { useDispatch, useSelector } from 'src/store';
import { getCountryList } from 'src/slices/lookup';
import { parsePhone, phoneMask } from 'src/utils/phone';
import Page from 'src/components/Page';

interface Props {
  [key: string]: any;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {}
}));

const Register: FC<Props> = ({ className, onTermsClick, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [completed, setCompleted] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(
    false
  );

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    dispatch(getCountryList());
  }, [dispatch, getCountryList]);

  return (
    <Page className={classes.root} title="Register">
      <Typography variant="h2">BVPort</Typography>
      <Typography variant="body1">Please enter your information</Typography>

      {!completed ? (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
            notes: '',
            clientName: '',
            agree: false,
            submit: null
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .max(255)
              .required(validationMessage('First Name')),
            lastName: Yup.string()
              .max(255)
              .required(validationMessage('Last Name')),
            email: Yup.string()
              .email('Must be a Valid Email')
              .max(255)
              .required(validationMessage('Email Address')),
            clientName: Yup.string()
              .max(255)
              .required(validationMessage('Company Name')),
            phoneNumber: Yup.string()
              .required(validationMessage('Phone Number'))
              .matches(
                phoneRegEx,
                'The phone must have a correct USA phone format'
              ),
            password: Yup.string()
              .required('Please enter your new password')
              .matches(
                passwordRegEx,
                'Password needs to be 8 – 14 characters with at least 1 letter, 1 number and 1 special character.'
              ),
            confirmPassword: Yup.string()
              .required('Please confirm password')
              .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            notes: Yup.string().max(
              255,
              'Comments must not exceed 500 characters'
            ),
            agree: Yup.boolean()
              .required()
              .oneOf([true], 'You must agree to the Terms & Conditions')
          })}
          onSubmit={async (values, actions) => {
            try {
              const user = {
                ...values,
                phoneNumber: parsePhone(values?.phoneNumber)
              };

              await axios.post<IUser[]>(urlBuilder('User/Register'), user);

              submitSuccess('Successfully Registered User', actions);
              setCompleted(true);
            } catch (err) {
              submitError(err, actions);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Box mt={2}>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      required
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      label="First Name"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      required
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      label="Last Name"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      name="email"
                      onBlur={handleBlur}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      onChange={(e) => {
                        setFieldValue('email', isBvEmail(e.target.value));
                        handleChange(e);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      error={Boolean(touched.clientName && errors.clientName)}
                      fullWidth
                      helperText={touched.clientName && errors.clientName}
                      label="Company Name"
                      name="clientName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.clientName}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputMask
                      mask={phoneMask}
                      disabled={false}
                      maskChar={null}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phoneNumber}
                      label="Phone Number"
                      name="phoneNumber"
                    >
                      {(inputProps) => (
                        <TextField
                          required
                          {...inputProps}
                          type="tel"
                          error={Boolean(
                            touched.phoneNumber && errors.phoneNumber
                          )}
                          fullWidth
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword((prev) => !prev)}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      fullWidth
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      label="Confirm Password"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmPassword}
                      variant="outlined"
                      type={showConfirmPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowConfirmPassword((prev) => !prev)
                              }
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.notes && errors.notes)}
                      multiline
                      rows={3}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      label="Comments/Nature of Request"
                      name="notes"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                      variant="outlined"
                      type="text"
                      inputProps={{ maxLength: 500 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          required
                          color="primary"
                          name="agree"
                          checked={values.agree}
                          onChange={() => setFieldValue('agree', !values.agree)}
                        />
                      }
                      label={
                        <>
                          I agree to the{' '}
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              onTermsClick(e);
                            }}
                            color="primary"
                          >
                            Terms &amp; Conditions
                          </Link>
                        </>
                      }
                    />
                    <ErrorPanel
                      errors={errors}
                      values={values}
                      touched={touched}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box mt={1}>
                      <Button
                        fullWidth
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Register
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box mt={1}>
                      <Button
                        fullWidth
                        color="primary"
                        to="/login"
                        component={RouterLink}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <Box mt={3}>
          <Alert severity="info">
            Your account was created and you will receive instructions soon!
          </Alert>
          <Box mt={3}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              to="/login"
              component={RouterLink}
            >
              Back to Login
            </Button>
          </Box>
        </Box>
      )}
    </Page>
  );
};

Register.propTypes = {
  className: PropTypes.string
};

export default Register;
