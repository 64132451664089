import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import * as api from 'src/apis/lookup';
import { IProjectStatusList } from 'src/types/IProjectStatusList';
import { IProgramStatusList } from 'src/types/IProgramStatusList';
import { IActivityTypeList } from 'src/types/IActivityTypeList';
import { IDocumentCategories } from 'src/types/IDocumentCategories';
import { IAssessmentTypeList } from 'src/types/IAssessmentTypeList';
import { ICountry } from 'src/types/ICountry';
import { INotificationFrequencyList } from 'src/types/INotificationFrequencyList';
import { IFileStatusList } from 'src/types/IFileStatusList';
import { IRole } from 'src/types/IRole';
import { ISiteTypeList } from 'src/types/ISiteTypeList';
import { IClientTypeList } from 'src/types/IClientTypeList';
import { IGridColumnSizeList } from 'src/types/IGridColumnSizeList';

interface LookupState {
  projectStatusList: IProjectStatusList[];
  programStatusList: IProgramStatusList[];
  activityTypeList: IActivityTypeList[];
  documentCategories: IDocumentCategories[];
  countryList: ICountry[];
  notificationFrequencyList: INotificationFrequencyList[];
  assessmentTypeList: IAssessmentTypeList[];
  fileStatusList: IFileStatusList[];
  siteTypeList: ISiteTypeList[];
  clientTypeList: IClientTypeList[];
  isLoading: boolean;
  roles: IRole[];
  gridColumnSizeList: IGridColumnSizeList[];
}

const initialState: LookupState = {
  projectStatusList: [],
  programStatusList: [],
  activityTypeList: [],
  documentCategories: [],
  countryList: [],
  notificationFrequencyList: [],
  assessmentTypeList: [],
  fileStatusList: [],
  siteTypeList: [],
  clientTypeList: [],
  isLoading: false,
  roles: [],
  gridColumnSizeList: []
};

const slice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    getProjectStatusList(
      state: LookupState,
      action: PayloadAction<{ projectStatusList: IProjectStatusList[] }>
    ) {
      const { projectStatusList } = action.payload;
      state.projectStatusList = projectStatusList;
      state.isLoading = false;
    },

    getFileStatusList(
      state: LookupState,
      action: PayloadAction<{ fileStatusList: IFileStatusList[] }>
    ) {
      const { fileStatusList } = action.payload;
      state.fileStatusList = fileStatusList;
      state.isLoading = false;
    },

    getProgramStatusList(
      state: LookupState,
      action: PayloadAction<{ programStatusList: IProgramStatusList[] }>
    ) {
      const { programStatusList } = action.payload;
      state.programStatusList = programStatusList;
      state.isLoading = false;
    },

    getActivityTypeList(
      state: LookupState,
      action: PayloadAction<{ activityTypeList: IActivityTypeList[] }>
    ) {
      const { activityTypeList } = action.payload;
      state.activityTypeList = activityTypeList;
      state.isLoading = false;
    },

    getAssessmentTypeList(
      state: LookupState,
      action: PayloadAction<{ assessmentTypeList: IAssessmentTypeList[] }>
    ) {
      const { assessmentTypeList } = action.payload;
      state.assessmentTypeList = assessmentTypeList;
      state.isLoading = false;
    },

    getDocumentCategories(
      state: LookupState,
      action: PayloadAction<{ documentCategories: IDocumentCategories[] }>
    ) {
      const { documentCategories } = action.payload;
      state.documentCategories = documentCategories;
      state.isLoading = false;
    },

    getCountryList(
      state: LookupState,
      action: PayloadAction<{ countryList: ICountry[] }>
    ) {
      const { countryList } = action.payload;
      state.countryList = countryList;
      state.isLoading = false;
    },

    getNotificationFrequencyList(
      state: LookupState,
      action: PayloadAction<{
        notificationFrequencyList: INotificationFrequencyList[];
      }>
    ) {
      const { notificationFrequencyList } = action.payload;
      state.notificationFrequencyList = notificationFrequencyList;
      state.isLoading = false;
    },
    getRoles(state: LookupState, action: PayloadAction<{ roles: IRole[] }>) {
      const { roles } = action.payload;

      state.roles = roles.sort((a, b) => a.displayOrder - b.displayOrder);
    },
    getGridColumnSizeList(
      state: LookupState,
      action: PayloadAction<{
        gridColumnSizeList: IGridColumnSizeList[];
      }>
    ) {
      const { gridColumnSizeList } = action.payload;
      state.gridColumnSizeList = gridColumnSizeList;
      state.isLoading = false;
    },
    setSiteTypeList(
      state: LookupState,
      action: PayloadAction<{ siteTypeList: ISiteTypeList[] }>
    ) {
      const { siteTypeList } = action.payload;
      state.siteTypeList = siteTypeList;
    },
    setClientTypeList(
      state: LookupState,
      action: PayloadAction<{ clientTypeList: IClientTypeList[] }>
    ) {
      const { clientTypeList } = action.payload;
      state.clientTypeList = clientTypeList;
    },

    isLoading(state: LookupState, action: PayloadAction) {
      state.isLoading = true;
    }
  }
});

export const reducer = slice.reducer;

export const getFileStatusList = (): AppThunk => async (dispatch, getState) => {
  const { lookups } = getState();
  if (lookups.fileStatusList.length > 0) return;

  const fileStatusList = await api.getFileStatusList();

  dispatch(slice.actions.getFileStatusList({ fileStatusList }));
};

export const getProjectStatusList = (): AppThunk => async (
  dispatch,
  getState
) => {
  const { lookups } = getState();
  if (lookups.projectStatusList.length > 0) return;

  const projectStatusList = await api.getProjectStatusList();

  dispatch(slice.actions.getProjectStatusList({ projectStatusList }));
};

export const getProgramStatusList = (): AppThunk => async (
  dispatch,
  getState
) => {
  const { lookups } = getState();
  if (lookups.programStatusList.length > 0) return;

  dispatch(slice.actions.isLoading());

  const programStatusList = await api.getProgramStatusList();

  dispatch(
    slice.actions.getProgramStatusList({
      programStatusList
    })
  );
};

export const getActivityTypeList = (): AppThunk => async (
  dispatch,
  getState
) => {
  const { lookups } = getState();
  if (lookups.activityTypeList.length > 0) return;

  dispatch(slice.actions.isLoading());

  const activityTypeList = await api.getActivityTypeList();

  dispatch(slice.actions.getActivityTypeList({ activityTypeList }));
};

export const getAssessmentTypeList = (): AppThunk => async (
  dispatch,
  getState
) => {
  const { lookups } = getState();
  if (lookups.assessmentTypeList.length > 0) return;

  dispatch(slice.actions.isLoading());

  const assessmentTypeList = await api.getAssessmentTypeList();

  dispatch(slice.actions.getAssessmentTypeList({ assessmentTypeList }));
};

export const getDocumentCategories = (): AppThunk => async (
  dispatch,
  getState
) => {
  const { lookups } = getState();
  if (lookups.documentCategories.length > 0) return;

  dispatch(slice.actions.isLoading());

  const documentCategories = await api.getDocumentCategories();

  dispatch(slice.actions.getDocumentCategories({ documentCategories }));
};

export const getCountryList = (): AppThunk => async (dispatch, getState) => {
  const { lookups } = getState();
  if (lookups.countryList.length > 0) return;

  dispatch(slice.actions.isLoading());

  const countryList = await api.getCountryList();

  dispatch(slice.actions.getCountryList({ countryList }));
};

export const getNotificationFrequencyList = (): AppThunk => async (
  dispatch,
  getState
) => {
  const { lookups } = getState();
  if (lookups.notificationFrequencyList.length > 0) return;

  dispatch(slice.actions.isLoading());

  const notificationFrequencyList = await api.getNotificationFrequencyList();

  dispatch(
    slice.actions.getNotificationFrequencyList({
      notificationFrequencyList
    })
  );
};

export const getRoles = (): AppThunk => async (dispatch) => {
  const roles = await api.getRoles();
  dispatch(slice.actions.getRoles({ roles }));
};

export const getGridColumnSizeList = (): AppThunk => async (
  dispatch,
  getState
) => {
  const { lookups } = getState();
  if (lookups.gridColumnSizeList.length > 0) return;

  dispatch(slice.actions.isLoading());

  const gridColumnSizeList = await api.getGridColumnSizeList();

  dispatch(
    slice.actions.getGridColumnSizeList({
      gridColumnSizeList
    })
  );
};

export const getSiteTypeList = (): AppThunk => async (dispatch) => {
  const siteTypeList = await api.getSiteTypeList();
  dispatch(slice.actions.setSiteTypeList({ siteTypeList }));
};

export const getClientTypeList = (): AppThunk => async (dispatch) => {
  const clientTypeList = await api.getClientTypeList();
  dispatch(slice.actions.setClientTypeList({ clientTypeList }));
};

export default slice;
