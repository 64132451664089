import React, { useState, useEffect, RefObject, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import type { FC } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  parentBox: {
    width: 'inherit'
  },
  footerBox: {
    padding: '4px',
    bottom: 0,
    borderRadius: 4,
    background: '#fff',
    position: 'static',
    width: 'inherit',
    zIndex: theme.zIndex.drawer - 1
  },
  sticky: {
    position: 'fixed',
    boxShadow: '0 -3px 10px rgba(0, 0, 0, 0.15)'
  }
}));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  const elementRef = useRef(null);

  useEffect(() => {
    function updateSize() {
      if (elementRef?.current) {
        setSize([
          elementRef?.current?.offsetHeight,
          elementRef?.current?.offsetWidth
        ]);
      }
    }
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [elementRef?.current]);

  return { elementRef, size };
}

function useIntersectionObserver(
  elementRef: RefObject<Element>,
  { threshold = 0, root = null, rootMargin = '0%' }: IntersectionObserverInit
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;
    if (!hasIOSupport || !node) return;
    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);
    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin]);

  return entry;
}

interface Props {
  children?: any;
  stickyComponent?: any;
  disabled?: boolean;
  footerClassName?: string;
}

const ViewPortClamp: FC<Props> = ({
  disabled,
  stickyComponent: StickyComponent = <></>,
  footerClassName,
  children
}) => {
  const classes = useStyles();
  const { elementRef, size } = useWindowSize();

  // parent
  const parentIntersectionRef = useRef(null);
  const parentEntry = useIntersectionObserver(parentIntersectionRef, {
    threshold: 0.1
  });
  const isParentInViewPort = !!parentEntry?.isIntersecting;

  // footer
  const intersectionRef = useRef(null);
  const entry = useIntersectionObserver(intersectionRef, {
    threshold: 0.95,
    rootMargin: '-40px 0px -40px 0px'
  });
  const isInViewPort = !!entry?.isIntersecting;
  const isStickyRendering = !isInViewPort && isParentInViewPort && !disabled;

  console.log('isInViewPort', isInViewPort);

  return (
    <>
      <div ref={parentIntersectionRef} className={classes.parentBox}>
        <div ref={elementRef}>{children}</div>

        <div
          style={{ height: '1px', visibility: 'hidden' }}
          ref={intersectionRef}
        />
        <Box
          className={clsx(classes.footerBox, footerClassName, {
            [classes.sticky]: isStickyRendering
          })}
          style={{ width: size?.[1] }}
        >
          <StickyComponent />
        </Box>
      </div>
    </>
  );
};

export { useWindowSize, useIntersectionObserver };
export default ViewPortClamp;
