import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'axios';
import urlBuilder from 'src/utils/urlBuilder';
import type { ISite } from 'src/types/ISite';
import * as api from 'src/apis/site';
import { ISiteTypeList } from 'src/types/ISiteTypeList';
import { ISummary } from 'src/types/ISummary';
import Site from 'src/models/Site';
interface SitesState {
  newSite: ISite;

  site: ISite;
  currentSite: ISite;

  sites: ISite[];
  currentSites: ISite[];
  isLoading: boolean;

  currentSiteSummary: ISummary;
  isLoadingSummary: boolean;

  siteTypes: ISiteTypeList[];

  response: any;
  request: any;
}

const initialState: SitesState = {
  newSite: null,

  site: null,
  currentSite: null,

  sites: [],
  currentSites: [],
  isLoading: false,

  currentSiteSummary: null,
  isLoadingSummary: false,

  siteTypes: [],

  response: {
    data: {
      result: [],
      pageNumber: 0,
      totalPages: 0,
      totalRecords: 0
    }
  },
  request: {}
};

const slice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setRequest(
      state: SitesState,
      action: PayloadAction<{ request: any; id?: any }>
    ) {
      const { request } = action.payload;
      state.request = request;
      state.isLoading = false;
    },

    setResponse(state: SitesState, action: PayloadAction<{ response }>) {
      const { response } = action.payload;
      state.response = response;
      state.isLoading = false;
    },

    setSite(state: SitesState, action: PayloadAction<{ site: ISite }>) {
      const { site } = action.payload;
      state.site = site;
      state.isLoading = false;
    },

    setSites(state: SitesState, action: PayloadAction<{ sites: ISite[] }>) {
      const { sites } = action.payload;
      state.sites = sites;
      state.isLoading = false;
    },

    setCurrentSite(state: SitesState, action: PayloadAction<{ site: ISite }>) {
      const { site } = action.payload;
      state.currentSite = site;
      state.isLoading = false;
    },

    setCurrentSites(
      state: SitesState,
      action: PayloadAction<{ sites: ISite[] }>
    ) {
      const { sites } = action.payload;
      state.currentSites = sites;
      state.isLoading = false;
    },

    setCurrentSiteSummary(
      state: SitesState,
      action: PayloadAction<{ summary: ISummary }>
    ) {
      const { summary } = action.payload;
      state.currentSiteSummary = summary;
      state.isLoadingSummary = false;
    },

    getSiteBySiteId(state: SitesState, action: PayloadAction<{ site: ISite }>) {
      const { site } = action.payload;
      state.currentSite = site;
    },

    getSite(state: SitesState, action: PayloadAction<{ site: ISite }>) {
      const { site } = action.payload;
      state.site = site;
    },

    createSite(state: SitesState, action: PayloadAction<{ site: ISite }>) {
      const { site } = action.payload;
      state.sites = [...state.sites, site];
    },

    updateSite(state: SitesState, action: PayloadAction<{ site: ISite }>) {
      const { site } = action.payload;
      state.sites = state.sites.map((x) =>
        x.siteID === site.siteID ? site : x
      );
      state.currentSite = site;
    },

    deleteSite(state: SitesState, action: PayloadAction<{ siteId: number }>) {
      const { siteId } = action.payload;
      state.sites = state.sites.filter((x) => x.siteID !== siteId);
    },

    getSiteTypeList(
      state: SitesState,
      action: PayloadAction<{ siteTypes: ISiteTypeList[] }>
    ) {
      const { siteTypes } = action.payload;
      state.siteTypes = siteTypes;
    },

    isLoading(state: SitesState, action: PayloadAction) {
      state.response = {
        data: {
          result: [],
          pageNumber: 0,
          totalPages: 0,
          totalRecords: 0
        }
      };
      state.isLoading = true;
    },

    isLoadingSummary(state: SitesState, action: PayloadAction) {
      state.isLoadingSummary = true;
    },

    reset(state: SitesState, action: PayloadAction<{}>) {
      state.currentSite = null;
      state.currentSiteSummary = null;
    }
  }
});

export const reducer = slice.reducer;

export const getSitesByQueryRaw = (baseQuery): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getSitesByQueryRaw(baseQuery);
  dispatch(slice.actions.setResponse({ response }));
  dispatch(slice.actions.setRequest({ request: baseQuery }));
};

export const getSitesByProgramId = (
  baseQuery,
  programId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getSitesByProgramId(baseQuery, programId);
  dispatch(slice.actions.setResponse({ response }));
  //dispatch(slice.actions.setRequest({ baseQuery, id: programId }));
};

export const getSitesByProjectId = (
  baseQuery,
  projectId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getSitesByProjectId(baseQuery, projectId);
  dispatch(slice.actions.setResponse({ response }));
  //dispatch(slice.actions.setRequest({ baseQuery, id: programId }));
};

// old

export const getSitesByClientId = (clientId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const sites = await api.getSitesByClientId(clientId);
  dispatch(slice.actions.setCurrentSites({ sites }));
};

export const getSitesByUserId = (userId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const sites = await api.getSitesByUserId(userId);
  dispatch(slice.actions.setCurrentSites({ sites }));
};

export const getSiteSummaryBySiteId = (siteId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoadingSummary());
  const summary = await api.getSiteSummaryBySiteId(siteId);
  dispatch(slice.actions.setCurrentSiteSummary({ summary }));
};

export const getSiteBySiteId = (siteId: number): AppThunk => async (
  dispatch
) => {
  const site = await api.getSiteBySiteId(siteId);
  if (site) await site.loadAvatar();
  dispatch(slice.actions.setCurrentSite({ site }));
};

export const createSite = (site: any): AppThunk => async (dispatch) => {
  await axios.post<boolean>(urlBuilder('Client/AddSite'), site);
  dispatch(slice.actions.createSite(site));
};

export const updateSite = (site: ISite): AppThunk => async (dispatch) => {
  const updatedSite = await api.updateSite(site);
  dispatch(
    slice.actions.updateSite({
      site: new Site({
        ...site,
        ...updatedSite
      })
    })
  );
};

export const deleteSiteBySiteId = (siteId: number): AppThunk => async (
  dispatch
) => {
  await api.deleteSiteBySiteId(siteId);
  dispatch(slice.actions.deleteSite({ siteId }));
};

export const reset = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.reset({}));
};

export default slice;
