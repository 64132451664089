import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import {
  Button,
  Portal,
  makeStyles,
  Typography,
  Box,
  IconButton
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import { useSelector } from 'src/store';
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    backdropFilter: 'url(filters.svg#filter) blur(4px) saturate(50%)',
    color: theme.palette.common.white,
    borderTop: '1px solid #fff',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    padding: theme.spacing(1.5),
    left: 0,
    textAlign: 'center',
    zIndex: 2000,
    display: 'flex'
  }
}));

const ServiceWorkerNotification: FC = () => {
  const classes = useStyles();

  const {
    serviceWorkerInitialized,
    serviceWorkerUpdated,
    serviceWorkerRegistration
  } = useSelector((state) => state.serviceWorker);

  const [open, setOpen] = useState<boolean>(false);

  const handleUpdateServiceWorker = () => {
    setOpen(false);
    const registrationWaiting = serviceWorkerRegistration?.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    if (serviceWorkerUpdated) {
      setOpen(true);
    }
  }, [serviceWorkerUpdated]);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Box style={{ flex: 1 }}>
          <Typography>
            There is a new version of the application available, would you like
            to update?
            <span>
              <Button
                onClick={handleUpdateServiceWorker}
                variant="contained"
                color="primary"
                style={{ marginLeft: 10 }}
              >
                Update to Current Version
              </Button>
            </span>
          </Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => setOpen(false)} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
      </div>
    </Portal>
  );
};

export default ServiceWorkerNotification;
