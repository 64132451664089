import IAction from 'src/types/IAction';
import { ACTION_TYPES } from './actions';
import { IState } from './state';

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case ACTION_TYPES.INITIALIZE:
      return {
        ...state,
        isInitialized: true,
        isLoading: false,
        isDirty: true,
        params: {
          ...state.params,
          ...action.payload.params
        }
      };
      break;

    case ACTION_TYPES.LOADING:
      return {
        ...state,
        isLoading: true
      };
      break;

    case ACTION_TYPES.SET_SEARCH_PARAMS:
      return {
        ...state,
        isDirty: true,
        params: {
          ...state.params,
          ...action.payload
        }
      };
      break;

    case ACTION_TYPES.SET_SITES:
      const { sites, totalRecords } = action.payload;

      return {
        ...state,
        isDirty: false,
        isLoading: false,
        // apiCancelToken: null,
        sites,
        totalRecords
      };
      break;

    case ACTION_TYPES.DELETE_SITE:
      return {
        ...state,
        isDirty: true,
        isLoading: false,
        sites: state.sites.filter((x) => x.siteID !== action.payload.siteID)
      };
      break;

    case ACTION_TYPES.CANCEL_TOKEN_SOURCE:
      return {
        ...state,
        apiCancelToken: action.payload
      };
      break;

    case ACTION_TYPES.SET_PAGINATION:
      return {
        ...state,
        isDirty: true,
        pagination: action.payload
      };
      break;

    case ACTION_TYPES.SET_STATE:
      return {
        ...state,
        ...action.payload
      };
      break;

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }

  return state;
};

export default reducer;
