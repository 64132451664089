import axios from 'axios';
import FileSaver from 'file-saver';

import { Levels } from 'src/types/Levels';
import urlBuilder from 'src/utils/urlBuilder';

export const getTemplateFile = async (level: Levels) => {
  const response = await axios.get(urlBuilder('Document/GetTemplateFile'), {
    params: {
      templateName: `${level.toLowerCase()}s`
    },
    responseType: 'arraybuffer'
  });

  const fileName = `${level}sTemplate.xlsx`;

  FileSaver.saveAs(
    new Blob([response.data], { type: 'application/octet-stream' }),
    fileName
  );
};
