import React from 'react';
import type { FC } from 'react';
import { Typography, Box } from '@material-ui/core';

interface PrivacyProps {
  [key: string]: any;
}

const Privacy: FC<PrivacyProps> = (props) => {
  return (
    <div>
      <Typography variant="h1" color="primary">
        The Bureau Veritas Commitment to Privacy.
      </Typography>
      <Box mt={2}>
        <Typography variant="body1">
          Our Privacy Policy was developed as an extension of our commitment to
          combine the highest-quality products and services with the highest
          level of integrity in dealing with our clients and partners. The
          Policy is designed to assist you in understanding how we collect, use
          and safeguard the personal information you provide to us and to assist
          you in making informed decisions when using our Web Site. This
          statement will be continuously assessed against new technologies,
          business practices and our customers’ needs.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h4">YOUR CONSENT</Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          By using the Web Site, you signify your consent to Bureau Veritas
          collection, use, and disclosure of your personal information in
          accordance with this Policy.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h4">WHAT INFORMATION DO WE COLLECT?</Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          When you visit Bureau Veritas, you may provide us with two types of
          information: personal information you knowingly choose to disclose
          that is collected on an individual basis, and usage information that
          is automatically collected on an aggregate basis as you and others
          browse our Web Site.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h4">
          <i>Information that is Automatically Collected</i>
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          Non-Identifiable Information – Like many other Web sites, Bureau
          Veritas automatically collects certain non-identifiable information
          regarding Web Site users, such as the Internet Protocol (IP) address
          of your computer, the IP address of your Internet Service Provider,
          the date and time you access the Web Site, the Internet address of the
          Web site from which you linked directly to the Web Site, the operating
          system you are using, the sections of the Web Site you visit, the Web
          Site pages read and images viewed, and the content you download from
          the Web Site. This non-identifiable information is used for Web Site
          and system administration purposes and to improve the Web Site. Your
          non-identifiable information may be disclosed to others and
          permanently archived for future use.
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          Tracking Information – Bureau Veritas may use non-personal information
          to create aggregate tracking information reports regarding Web Site
          user demographics, Web Site traffic patterns, and Web Site purchases,
          and then provide those reports to partners and others. None of the
          tracking information in the reports can be connected to the identities
          or other personal information of individual users. Bureau Veritas may
          also link tracking information with personal information voluntarily
          provided by Web Site users. Once such a link is made, all of the
          linked information is treated as personal information and will be used
          and disclosed only in accordance with this Policy.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h4">
          <i>Information You Choose to Provide</i>
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          During your use of the Web Site, you may be asked to voluntarily
          provide certain personal information (such as your name, email
          address, postal address, telephone number, or credit card information)
          for purposes such as facilitating communications with you, applying
          for access to certain special features of the Web Site, or carrying
          out transactions. It is always your choice whether or not to provide
          personal information. If you choose not to provide requested personal
          information, you will not be able to use certain features of the Web
          Site and you will not be able to engage in transactions through the
          Web Site.Use of Your Personal Information – Bureau Veritas may use
          your personal information to contact and correspond with you, to
          respond to your inquiries, to process transactions and payments, and
          to facilitate and enhance your use of the Web Site. In addition to
          providing the foregoing information to our partners, if you choose to
          correspond further with us through email, we may retain the content of
          your email messages together with your email address and our
          responses. We provide the same protections for these electronic
          communications that we employ in the maintenance of information
          received by mail and telephone.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h4">
          <i>HOW DO WE USE THE INFORMATION THAT YOU PROVIDE TO US?</i>
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body1">
          Broadly speaking, we use personal information for purposes of
          administering our business activities, providing customer service and
          making available other products and services to our customers and
          prospective customers. Occasionally, we may also use the information
          we collect to notify you about important changes to our Web site, new
          services and special offers we think you will find valuable. The lists
          used to send you product and service offers are developed and managed
          under our traditional corporate standards designed to safeguard the
          security and privacy of our clients. As our client, you always have
          the opportunity to notify us of your desire not to receive these
          offers by sending us an email request at
          BAPMClientPortal@bureauveritas.com. We may provide aggregate
          information about our customers, sales, Web site traffic patterns and
          related Web site information to our reputable third parties, but this
          information will not include personally identifying data, except as
          otherwise provided in this Privacy Policy.
        </Typography>
      </Box>
    </div>
  );
};

export default Privacy;
