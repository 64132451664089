import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import store from 'src/store';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';
import { SW_INIT, SW_UPDATE } from 'src/slices/service-worker';

enableES5();

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

console.log(`BVPORT - Publish Date: ${process.env.REACT_APP_PUBLISH_DATE}`);
console.log(`BVPORT - Environment: ${process.env.NODE_ENV}`);

serviceWorker.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: (reg) => store.dispatch({ type: SW_UPDATE, payload: reg })
});
