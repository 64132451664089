export const phoneRegEx = /^\+\s*1\s*\(\s*[02-9]\d{2}\)\s*\d{3}\s*-\s*\d{4}(\s(((ext:))(\s?\d+)?))?$/;

export const phoneMask = '+1 (999) 999-9999 ext:9999999';

export const parsePhone = (phoneNumber: string) => {
  if (!phoneNumber) return '';
  return phoneNumber.substr(phoneNumber.length - 4) === 'ext:'
    ? phoneNumber.replace('ext:', '').trim()
    : phoneNumber.trim();
};
