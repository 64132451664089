import { IProgram } from 'src/types/IProgram';
import faker from 'faker';
import { format } from 'src/utils/date';
import { removeEmpty, serializeObj } from 'src/utils/object';
import { getClientImageByClientId } from 'src/apis/client';
import imageEncode from 'src/utils/imageEncode';

export default class Program implements IProgram {
  managerName: string;
  createdByName?: string;
  programID?: number;
  programName: string;
  programCode: string;
  bvProjectNumber?: string;
  clientID: number;
  clientName: string;
  clientContactName?: string;
  clientContactEmail?: string;
  managerID: number;
  programManager?: string;
  state?: string;
  effectiveStartDate?: string;
  deliveryDate?: string;
  programStatus?: string;
  awardDate?: string;
  dueDate?: string;
  programDescription?: string;
  notes?: string;
  confidential?: boolean;
  quireID?: string;
  visionID?: string;
  assetCalcID?: string;
  proTrackID?: string;
  referenceNumber?: string;
  createdDate?: string;
  createdBy?: number;
  modifiedDate?: string;
  modifiedBy?: number;
  modifiedByName?: string;
  programStatusID: number;
  active?: boolean;
  avatar?: string;

  constructor(init?: Partial<IProgram>) {
    Object.assign(this, init);
  }

  generateMockData() {
    this.programName = faker.commerce.productName();
    this.programCode = String(faker.random.number());
    this.notes = faker.lorem.paragraph();
    this.notes = faker.lorem.paragraph();
    this.confidential = true;
  }

  serialize(): IProgram {
    return removeEmpty(serializeObj(this));
  }

  async loadAvatar() {
    let response = null;

    try {
      response = await getClientImageByClientId(this.clientID);
    } catch (e) {}

    this.avatar = response?.data ? imageEncode(response.data) : '';
  }

  public static deserialize(data: IProgram) {
    let program = new Program();

    for (let key in data) {
      program[key] = data[key];
    }

    program.createdDate = format(data.createdDate);
    program.modifiedDate = format(data.modifiedDate);

    return program;
  }
}
