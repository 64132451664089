import React from 'react';
import type { FC } from 'react';
import {
  ListItem,
  ListItemProps,
  ListItemText,
  makeStyles,
  Avatar,
  Typography,
  ListItemAvatar,
  IconButton
} from '@material-ui/core';
import {
  InsertDriveFile as InsertDriveFileIcon,
  Message as MessageIcon,
  MergeType as MergeTypeIcon,
  CheckCircle as CheckCircleIcon,
  OpenInNew as OpenInNewIcon
} from '@material-ui/icons';

import type { Theme } from 'src/theme';
import { IRecentActivity } from 'src/types/IRecentActivity';
import { format } from 'src/utils/date';
import { useHistory, useLocation } from 'react-router';

interface NotificationItemProps extends ListItemProps {
  className?: string;
  recentActivity?: IRecentActivity;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    padding: 10,
    borderBottom: '1px solid #ccc'
  },
  content: {
    flex: 1
  },
  icon: {
    paddingRight: 10
  }
}));

const NotificationItem: FC<NotificationItemProps> = ({
  className,
  recentActivity,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const getOpenURL = (): string => {
    let url = '';

    if (recentActivity?.activityLevel.toLowerCase() === 'client')
      url = `/app/clients/edit/${recentActivity.clientID}`;

    if (recentActivity?.activityLevel.toLowerCase() === 'site')
      url = `/app/sites/edit/${recentActivity.siteID}`;

    if (recentActivity?.activityLevel.toLowerCase() === 'project')
      url = `/app/projects/edit/${recentActivity.projectID}`;

    if (recentActivity?.activityLevel.toLowerCase() === 'program')
      url = `/app/programs/edit/${recentActivity.programID}`;

    return url;
  };

  const showOpen = location.pathname !== getOpenURL();

  const handleOpen = () => {
    history.push(getOpenURL());
  };

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        {recentActivity?.activityTypeID === 1 && <InsertDriveFileIcon />}
        {recentActivity?.activityTypeID === 2 && <InsertDriveFileIcon />}
        {recentActivity?.activityTypeID === 3 && <MergeTypeIcon />}
        {recentActivity?.activityTypeID === 4 && <MessageIcon />}
        {recentActivity?.activityTypeID === 5 && <CheckCircleIcon />}
      </div>
      <div className={classes.content}>
        <Typography variant="caption" style={{ color: '#808080' }}>
          {recentActivity?.modifiedByName} -{' '}
          {format(recentActivity?.modifiedDate, 'hh:mm A')}
        </Typography>
        <Typography>
          {recentActivity?.activityTypeName}
          <br />

          {recentActivity?.activityLevel.toLowerCase() === 'client' && (
            <>Client Name: {recentActivity?.clientName}</>
          )}

          {recentActivity?.activityLevel.toLowerCase() === 'site' && (
            <>Site Name: {recentActivity?.siteName}</>
          )}

          {recentActivity?.activityLevel.toLowerCase() === 'program' && (
            <>Program Name: {recentActivity?.programName}</>
          )}

          {recentActivity?.activityLevel.toLowerCase() === 'project' && (
            <>Project Name: {recentActivity?.projectName}</>
          )}
        </Typography>
      </div>
      {showOpen && (
        <div>
          <IconButton onClick={handleOpen}>
            <OpenInNewIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default NotificationItem;
