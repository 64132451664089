import axios from 'axios';
import User from 'src/models/User';
import { IUser } from 'src/types/IUser';
import { ISummary } from 'src/types/ISummary';
import urlBuilder from 'src/utils/urlBuilder';
import { Levels } from 'src/types/Levels';
import { getClientsByQueryRaw } from './client';
import { IClient } from 'src/types/IClient';
import { queryHelper } from './dynamic-query-helper';
import { IUserAccess } from 'src/types/IUserAccess';
import UserAccess from 'src/models/UserAccess';
interface IUserQuery {
  pageNumber: number;
  pagination: true;
  result: IUser[];
  rowsPerPage: number;
  totalPages: number;
  totalRecords: number;
  userId: number;
}

interface IUserAccessQuery {
  pageNumber: number;
  pagination: true;
  result: IUserAccess[];
  rowsPerPage: number;
  totalPages: number;
  totalRecords: number;
  userId: number;
}

interface IUserAuthenicationResponse extends IUser {
  userId: number;
  roleId: number;
}

export const authenicate = async (email, password) => {
  const response = await axios.post<IUserAuthenicationResponse>(
    urlBuilder('User/Authenticate'),
    { email, password }
  );

  const { userId, roleId, ...rest } = response.data;

  return new User({
    ...rest,
    userID: userId,
    roleID: roleId
  });
};

export const getMe = async () => {
  const response = await axios.get<IUserAuthenicationResponse>(
    urlBuilder('User/Me')
  );

  const { userId, roleId, ...rest } = response.data;

  return new User({
    ...rest,
    userID: userId,
    roleID: roleId
  });
};

export const getUsersByQueryRaw = async (query: any) => {
  return await axios.post<IUserQuery>(
    urlBuilder('User/GetUsersByDynamicQuery'),
    query
  );
};

export const getUserAccessByDynamicQuery = async (query: any) => {
  return await axios.post<IUserAccessQuery>(
    urlBuilder('User/GetUserAccessByDynamicQuery'),
    query
  );
};

export const getUsersByDynamicQuery = async (query: any, limit?: number) => {
  if (limit) {
    query = {
      ...query,
      rowsPerPage: limit,
      pagination: true,
      totalPages: 1
    };
  }
  const response = await axios.post<IUserQuery>(
    urlBuilder('User/GetUsersByDynamicQuery'),
    query
  );

  let users: User[] = response.data.result.map((x) => new User(x));

  return users;
};

export const getMyAccountInfo = async (): Promise<User> => {
  const response = await axios.get<IUser>(urlBuilder('User/GetMyAccountInfo'));
  return new User(response.data);
};

interface IGridColumnsResponse {
  columnCaption: string;
  columnName: string;
  columnOrder: number;
  createdBy: number;
  createdDate: string;
  gridCode: string;
  modifiedBy: number;
  modifiedDate: string;
  selected: boolean;
  userGridColumnID: number;
  userID: number;
}

export const getUserGridColumns = async (gridCode: string) => {
  const response = await axios.get<IGridColumnsResponse[]>(
    urlBuilder('User/GetUserGridColumns'),
    {
      params: { gridCode }
    }
  );

  return response.data;
};

export const getUsers = async () => {
  const users = await getUsersByDynamicQuery({});
  return users;
};

export const getUserByUserId = async (userId: number) => {
  const users = await getUsersByDynamicQuery({
    where: queryHelper.getUser(userId).getQuery()
  });

  return users[0] || null;
};

export const getUserAccessByUserId = async (userId: number) => {
  const users = await getUsersByDynamicQuery({
    where: queryHelper.getUser(userId).getQuery()
  });

  return users[0] || null;
};

// export const getUserAccessByClientIdOLD = async (clientId: number) => {
//   const response = await getUserAccessByDynamicQuery({
//     where: queryHelper.getClient(clientId).getQuery()
//   });

//   return response.data.result.map((x) => new User(x));
// // };

// export const getUserAccessByProgramIdOLD = async (programId: number) => {
//   const response = await getUserAccessByDynamicQuery({
//     where: queryHelper.getProgram(programId).getQuery()
//   });

//   return response.data.result.map((x) => new User(x));
// };

// export const getUserAccessBySiteIdOLD = async (siteId: number) => {
//   const response = await getUserAccessByDynamicQuery({
//     where: queryHelper.getSite(siteId).getQuery()
//   });

//   return response.data.result.map((x) => new User(x));
// };

// export const getUserAccessByProjectIdOLD = async (projectId: number) => {
//   const response = await getUserAccessByDynamicQuery({
//     where: queryHelper.getProject(projectId).getQuery()
//   });

//   return response.data.result.map((x) => new User(x));
// };

export const getUserAccessByClientId = async (query: any, clientId: number) => {
  return await axios.post<IUserQuery>(
    urlBuilder('User/GetUsersByClientId'),
    query,
    {
      params: {
        clientId
      }
    }
  );
};

export const getUserAccessByProgramId = async (
  query: any,
  programId: number
) => {
  return await axios.post<IUserQuery>(
    urlBuilder('User/GetUsersByProgramId'),
    query,
    {
      params: {
        programId
      }
    }
  );
};

export const getUserAccessBySiteId = async (query: any, siteId: number) => {
  return await axios.post<IUserQuery>(
    urlBuilder('User/GetUsersBySiteId'),
    query,
    {
      params: {
        siteId
      }
    }
  );
};

export const getUserAccessByProjectId = async (
  query: any,
  projectId: number
) => {
  return await axios.post<IUserQuery>(
    urlBuilder('User/GetUsersByProjectId'),
    query,
    {
      params: {
        projectId
      }
    }
  );
};

// export const getUsersByDocuementId = async (query: any) => {
//   return await axios.post<IUserQuery>(
//     urlBuilder('User/UsersByDocumentId'),
//     query
//   );
// };

export const getUserAccessByUserIdAndClientId = async (
  userId: number,
  clientId: number
) => {
  const response = await axios.get<IUserAccessQuery>(
    urlBuilder('User/GetUserAccessByUserIdAndClientId'),
    {
      params: {
        userId,
        clientId
      }
    }
  );

  return response.data.result.map((x) => new UserAccess(x));
};

export const getUserAccessTypeToLevel = async (
  userId: number,
  level: Levels,
  levelId: number
) => {
  const response = await axios.get<IUser[]>(
    urlBuilder('User/GetUserAccessTypeToLevel'),
    {
      params: {
        userId,
        level,
        levelId
      }
    }
  );

  return response.data;
};

export const doesUserHaveAccessToIds = async (
  userId: number,
  level: Levels | string,
  levelIds: string
) => {
  const response = await axios.get<IUser[]>(
    urlBuilder('User/DoesUserHaveAccessToIds'),
    {
      params: {
        userId,
        level,
        levelIds
      }
    }
  );

  return response.data;
};

export const getUsersBySearchValue = async (value, extraQuery?) => {
  let query = [];

  if (extraQuery) {
    query = extraQuery;
  }

  query = [
    ...query,
    {
      andOr: query.length === 0 ? '' : 'and',
      items: [
        {
          andOr: '',
          field: 'firstName',
          operator: 'contains',
          value
        },
        {
          andOr: 'or',
          field: 'lastName',
          operator: 'contains',
          value
        },
        {
          andOr: 'or',
          field: 'email',
          operator: 'contains',
          value
        },
        {
          andOr: 'or',
          field: 'fullName',
          operator: 'contains',
          value
        }
      ]
    }
  ];

  const users = await getUsersByDynamicQuery({
    where: query,
    orderBy: [
      {
        fieldName: 'firstName',
        orderType: 'asc'
      },
      {
        fieldName: 'lastName',
        orderType: 'asc'
      }
    ]
  });

  return users;
};

export const getUserSummaryByUserId = async (userId: number) => {
  const response = await axios.get<ISummary>(
    urlBuilder('Client/GetUserSummaryByUserId'),
    {
      params: {
        userId
      }
    }
  );

  return response.data;
};

export const createUser = async (user: IUser) => {
  const response = await axios.post<User>(urlBuilder('User/AddUser'), user);

  return response.data;
};

export const deleteUserByUserId = async (userId: number) => {
  await axios.post(urlBuilder('User/DeleteUser'), null, {
    params: {
      id: userId
    }
  });
};

export const deleteUser = async (user: IUser) => {
  return await axios.post<IUser>(urlBuilder('User/DeleteUser'), null, {
    params: {
      id: user.userID
    }
  });
};

export const updateUser = async (user: IUser) => {
  // filter out avatar data
  const { avatar, passwordHash, ...updateUser } = user;
  const response = await axios.post<IUser>(
    urlBuilder('User/UpdateUser'),
    updateUser,
    {
      params: {
        id: user.userID
      }
    }
  );

  return response.data;
};

interface IGridColumnsRequest {
  columnName: string;
  columnCaption: string;
  columnOrder?: number;
  selected?: boolean;
}

export const updateUserGridColumns = (gridCode: string, columns: string[]) => {
  return axios.post<IGridColumnsRequest[]>(
    urlBuilder('User/AddUserGridColumns'),
    columns.map((x) => ({
      columnName: x,
      columnCaption: x
    })),
    { params: { gridCode } }
  );
};

export const getUserImageByUserId = async (userId: number) => {
  try {
    return await axios.get(urlBuilder('User/GetUserImage'), {
      responseType: 'arraybuffer',
      params: {
        userId
      }
    });
  } catch (e) {}
};

export const postUserImageByUserId = async (userId: number, file: File) => {
  return axios.post(
    urlBuilder('User/PostUserImage'),
    Buffer.from(await file.arrayBuffer()),
    {
      headers: {
        'Content-Type': 'image/jpeg'
      },
      params: {
        userId
      }
    }
  );
};

export const uploadUserInfoFile = async (file: File, onUploadProgress) => {
  return axios.post(
    urlBuilder('User/UploadUserInfoFile'),
    Buffer.from(await file.arrayBuffer()),
    {
      headers: {
        'Content-Type': 'text/csv'
      },
      onUploadProgress
    }
  );
};

export const updateMyAccountInfo = async (user: IUser) => {
  const response = await axios.post<IUser>(
    urlBuilder('User/UpdateMyAccountInfo'),
    user,
    {
      params: {
        id: user.userID
      }
    }
  );

  return new User(response.data);
};

export const changePassword = async (password: string, user: IUser) => {
  return await axios.post<IUser>(
    urlBuilder('User/ResetPassword'),
    {
      password,
      confirmPassword: password,
      passwordChangedDate: new Date(),
      passwordActiveDays: user.passwordActiveDays
    },
    {
      params: {
        id: user.userID
      }
    }
  );
};

export const getTermsAndConditionsFile = async () => {
  return await axios.get<string>(urlBuilder('User/GetTermsAndConditionsFile'));
};

export const getUserAccess = async (userId: number) => {
  let response = await getClientsByQueryRaw({ userId });

  let clients: IClient[] = response.data.result;

  //const getAccess = await getUserAccessByUserIdAndClientId({ userId: currentUser.userID }, )
};
