import { useEffect, useState, useMemo, useRef } from 'react';
import { addDocument, postDocument } from 'src/apis/document';
import { createUploadFile } from 'src/factory/UploadFile';
import { useDispatch } from 'src/store';
import * as slices from 'src/slices/document';
import type { IUploadFile } from 'src/types/IUploadFile';
import Emitter from 'src/services/emitter';
import { submitSuccess } from 'src/components/Forms/FormikHelper';

interface UseFileUpload {
  files: IUploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<IUploadFile[]>>;
  bulkUpdate: (fileInfo: IUploadFile, files: IUploadFile[]) => void;
  updateAllFiles: (fileInfo: Partial<IUploadFile>) => void;
  updateFile: (updatedDile: IUploadFile) => void;
  setSystemFiles: (systemFiles: File[]) => void;
  uploadFiles: (globalSettings: Partial<IUploadFile>) => void;
  isValid: () => boolean;
}

const useFileUpload = (): UseFileUpload => {
  // for testing

  const [files, setFiles] = useState<IUploadFile[]>([]);
  const systemFiles = useRef([]);

  const dispatch = useDispatch();

  // const mockFiles = new Array(10)
  // .fill((x) => null)
  // .map((x) => createUploadFile().faker());

  // useEffect(() => {
  //   setFiles(mockFiles);
  // }, []);

  const uploadFiles = async (globalSettings: Partial<IUploadFile>) => {
    let queuedFiles = [...files];

    Emitter.emit('APP', {
      action: 'LOADER',
      isLoading: true,
      message: `Uploading ${queuedFiles.length} files, please be patient.`
    });

    for (let index = 0; index < queuedFiles.length; index++) {
      const file = queuedFiles[index];

      const updatedFile = {
        ...file,
        ...globalSettings
      };

      const document = updatedFile.serialize();

      try {
        const response = await addDocument(document.id, document);

        const currentFile = systemFiles.current.find(
          (systemFile) => systemFile.name === file.filename
        );

        await postDocument(response.documentID, currentFile);

        Emitter.emit('APP', {
          action: 'LOADER',
          isLoading: true,
          message: `Uploaded ${index + 1} of ${
            queuedFiles.length
          }, please be patient.`
        });
      } catch (err) {}
    }
    submitSuccess('Files Uploaded');

    Emitter.emit('FILE_MANAGER_ACTION', {
      type: 'ADD_DOCUMENT',
      payload: document
    });

    Emitter.emit('APP', { action: 'LOADER', isLoading: false });

    dispatch(slices.closeModal());
  };

  const setSystemFiles = (systemFilesToAdd: File[]) => {
    systemFiles.current = systemFilesToAdd;

    setFiles(
      systemFilesToAdd.map((file) => createUploadFile().deserialize(file))
    );
  };

  const updateFile = (updatedDile: IUploadFile) => {
    console.log('updatedFile');
    setFiles(
      files.map((file) => (file.id !== updatedDile.id ? file : updatedDile))
    );
  };

  const updateAllFiles = (fileInfo: Partial<IUploadFile>) => {
    console.log('bulk update');

    const filesToCommit = files.map((file) => ({
      ...file,
      ...fileInfo
    }));

    if (filesToCommit.length > 0) setFiles(filesToCommit);
  };

  const bulkUpdate = (fileInfo: IUploadFile, filesToUpdate: IUploadFile[]) => {
    console.log('bulk update');

    const {
      description,
      active,
      internal,
      classification,
      classificationId,
      assessmentType,
      assessmentTypeId,
      status,
      statusId,
      comments,
      useFilename
    } = fileInfo;

    const updateFiles = filesToUpdate.map((file) => ({
      ...file,
      description: useFilename ? file.filename : description,
      classification,
      classificationId,
      assessmentType,
      assessmentTypeId,
      status,
      statusId,
      active,
      internal,
      comments
    }));

    const filesToCommit = files.map((file) => {
      const updateFile = updateFiles.find((x) => x.id === file.id);
      return updateFile ? updateFile : file;
    });

    setFiles(filesToCommit);
  };

  const isValid = () => {
    return !files.some((file) => !file.isValid());
  };

  return useMemo(
    () => ({
      files,
      setFiles,
      bulkUpdate,
      updateAllFiles,
      updateFile,
      setSystemFiles,
      uploadFiles,
      isValid
    }),
    [files]
  );
};

export default useFileUpload;
