import React, { useMemo } from 'react';
import type { FC } from 'react';
import rules from './rbac-rules';
import useAuth from 'src/hooks/useAuth';
import { IUser } from 'src/types/IUser';

const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action.toLowerCase())) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

interface Props {
  [key: string]: any;
}

const Can: FC<Props> = (props) => {
  const { user } = useAuth();

  return check(rules, user?.roleID, props.perform, props.data)
    ? props.yes()
    : props.no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

const can = (user: IUser, action: string, data?: any) => {
  return check(rules, user.roleID, action, data);
};

export default Can;
export { can };
