import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import * as api from 'src/apis/comment';
import { IComment } from 'src/types/IComment';

interface State {
  comments: IComment[];
}

const initialState: State = {
  comments: []
};

const slice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    getComments(state: State, action: PayloadAction<{ comments: IComment[] }>) {
      const { comments } = action.payload;

      state.comments = comments;
    },
    setComment(state: State, action: PayloadAction<{ comment: IComment }>) {
      const { comment } = action.payload;

      state.comments = [...state.comments, comment];
    }
  }
});

export const reducer = slice.reducer;

export const resetComments = (): AppThunk => async (
  dispatch
) => {
  const comments = [];
  dispatch(slice.actions.getComments({ comments }));
};

export const getDocumentCommentsByDocumentId = (documentId): AppThunk => async (
  dispatch
) => {
  const comments = await api.getDocumentCommentsByDocumentId(documentId);

  dispatch(slice.actions.getComments({ comments }));
};

export const addComment = ($comment: IComment): AppThunk => async (
  dispatch
) => {
  const comment = await api.addDocumentComment($comment);

  dispatch(slice.actions.setComment({ comment }));
};

export default slice;
