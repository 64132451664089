import { Roles } from 'src/types/IRole';

const shareAcess = [
  'clients:share',
  'programs:share',
  'sites:share',
  'projects:share',
  'documents:share'
];

const generalAccess = [
  'clients',
  'clients:browse',
  'programs',
  'programs:browse',
  'sites',
  'sites:browse',
  'projects',
  'projects:browse',
  'documents',
  'notifications'
];

const fullAccess = [
  ...shareAcess,
  ...generalAccess,
  'clients:create',
  'clients:delete',
  'clients:edit',
  'clients:image:upload',
  'programs:create',
  'programs:delete',
  'programs:edit',
  'programs:batch_upload',
  // 'programs:image:upload',
  'sites:create',
  'sites:delete',
  'sites:edit',
  'sites:batch_upload',
  'projects:create',
  'projects:delete',
  'projects:edit',
  'users',
  'users:browse',
  'users:access:add',
  'users:access:remove',
  'files:add',
  'files:delete',
  'files:quick_upload',
  'files:comments:add',
  'documents:internal:only',
  'documents:classification'
];

const isMe = ({ userId, currentUserId }) => {
  if (!userId || !currentUserId) return false;
  return userId === currentUserId;
};

const rules = {
  [Roles.INTERNAL_USER_ADMIN]: {
    static: [
      ...fullAccess,
      'users:image:upload',
      'users:password:change',
      'users:client:edit',
      'users:user_type:edit',
      'users:client:name:edit',
      'users:create',
      'users:delete',
      'users:edit',
      'users:create:external_user_read_only',
      'users:create:external_user_full_access',
      'users:create:external_user_admin',
      'users:create:internal_user_read_only',
      'users:create:internal_user_full_access',
      'users:create:internal_user_admin',
      'users:access:edit',
      'users:access:client:add',
      'users:batch_upload'
    ]
  },
  [Roles.INTERNAL_USER_FULL_ACCESS]: {
    static: [...fullAccess],
    dynamic: {
      'users:image:upload': isMe,
      'users:password:change': isMe,
      'users:edit': isMe
    }
  },
  [Roles.INTERNAL_USER_READ_ONLY]: {
    static: [...generalAccess, 'users', 'users:browse'],
    dynamic: {
      'users:image:upload': isMe,
      'users:password:change': isMe,
      'users:edit': isMe
    }
  },
  [Roles.EXTERNAL_USER_ADMIN]: {
    static: [
      ...shareAcess,
      ...generalAccess,
      'users:client:name:edit',
      'clients:image:upload',
      // 'programs:image:upload',
      'users',
      'users:browse',
      'users:create',
      'users:delete',
      'users:edit',
      'users:access:edit',
      'users:access:add',
      'users:access:remove',
      'users:image:upload',
      'users:password:change',
      'users:user_type:edit',
      'users:create:external_user_read_only',
      'users:create:external_user_full_access',
      'users:create:external_user_admin',
      'files:add',
      'files:delete',
      'files:quick_upload',
      'files:comments:add'
    ]
  },

  [Roles.EXTERNAL_USER_FULL_ACCESS]: {
    static: [
      ...shareAcess,
      ...generalAccess,
      'files:add',
      'files:delete',
      'files:quick_upload',
      'files:comments:add'
    ],
    dynamic: {
      'users:image:upload': isMe,
      'users:password:change': isMe,
      'users:edit': isMe
    }
  },

  [Roles.EXTERNAL_USER_READ_ONLY]: {
    static: [...generalAccess],

    dynamic: {
      'users:image:upload': isMe,
      'users:password:change': isMe,
      'users:edit': isMe
    }
  }
};

export default rules;
