import type { CancelTokenSource } from 'axios';
import { IPager } from 'src/components/Tables/@hooks/usePagination';
import type IAction from 'src/types/IAction';
import { IProject } from '../@types/IProject';
import type { IProjectParams } from './controller';
import type { IState } from './state';

export enum ACTION_TYPES {
  INITIALIZE = 'INITIALIZE',
  LOADING = 'LOADING',
  SET_STATE = 'SET_STATE',
  CANCEL_TOKEN_SOURCE = 'CANCEL_TOKEN_SOURCE',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_PROJECTS = 'SET_PROJECTS',
  DELETE_PROJECTS = 'DELETE_PROJECTS',
  SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS'
}

export const ACTIONS = {
  loading: (): IAction => ({ type: ACTION_TYPES.LOADING, payload: true }),
  setState: (state: IState): IAction => ({
    type: ACTION_TYPES.SET_STATE,
    payload: state
  }),
  initialize: (state: IState): IAction => ({
    type: ACTION_TYPES.INITIALIZE,
    payload: state
  }),
  setCancelToken: (cancelToken: CancelTokenSource): IAction => ({
    type: ACTION_TYPES.CANCEL_TOKEN_SOURCE,
    payload: cancelToken
  }),
  setPagination: (pagination: IPager): IAction => ({
    type: ACTION_TYPES.SET_PAGINATION,
    payload: pagination
  }),
  setProjects: (
    projects: IProject[] = [],
    totalRecords: number = 0
  ): IAction => ({
    type: ACTION_TYPES.SET_PROJECTS,
    payload: {
      projects,
      totalRecords
    }
  }),
  deleteProject: (project: IProject): IAction => ({
    type: ACTION_TYPES.DELETE_PROJECTS,
    payload: project
  }),

  setSearchParams: (params: IProjectParams): IAction => ({
    type: ACTION_TYPES.SET_SEARCH_PARAMS,
    payload: params
  })
};
