import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import * as api from 'src/apis/project';
import { ISummary } from 'src/types/ISummary';
import Project from 'src/models/Project';

interface ProjectsState {
  newProject: Project;

  project: Project;
  currentProject: Project;

  projects: Project[];
  currentProjects: Project[];
  isLoading: boolean;

  currentProjectSummary: ISummary;
  currentProjectStatusSummary: ISummary;
  isLoadingSummary: boolean;

  isLoadingRecentProject: boolean;

  response: any;
  request: any;
}

const initialState: ProjectsState = {
  newProject: null,

  project: null,
  currentProject: null,

  projects: [],
  currentProjects: [],
  isLoading: false,

  currentProjectSummary: null,
  currentProjectStatusSummary: null,
  isLoadingSummary: false,
  isLoadingRecentProject: false,

  response: {
    data: {
      result: [],
      pageNumber: 0,
      totalPages: 0,
      totalRecords: 0
    }
  },
  request: {}
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setRequest(
      state: ProjectsState,
      action: PayloadAction<{ request: any; api: any }>
    ) {
      const { request } = action.payload;
      state.isLoading = false;
      state.request = request;
    },

    setResponse(state: ProjectsState, action: PayloadAction<{ response }>) {
      const { response } = action.payload;
      state.isLoading = false;
      state.response = response;
    },

    setProject(
      state: ProjectsState,
      action: PayloadAction<{ project: Project }>
    ) {
      const { project } = action.payload;
      state.isLoading = false;
      state.project = project;
    },

    setProjects(
      state: ProjectsState,
      action: PayloadAction<{ projects: Project[] }>
    ) {
      const { projects } = action.payload;
      state.isLoading = false;
      state.projects = projects;
    },

    setCurrentProject(
      state: ProjectsState,
      action: PayloadAction<{ project: Project }>
    ) {
      const { project } = action.payload;
      state.isLoading = false;
      state.currentProject = project;
    },

    setCurrentProjects(
      state: ProjectsState,
      action: PayloadAction<{ projects: Project[] }>
    ) {
      const { projects } = action.payload;
      state.isLoading = false;
      state.currentProjects = projects;
    },

    setCurrentProjectSummary(
      state: ProjectsState,
      action: PayloadAction<{ summary: ISummary }>
    ) {
      const { summary } = action.payload;
      state.currentProjectSummary = summary;
      state.isLoadingSummary = false;
    },

    setCurrentProjectStatusSummary(
      state: ProjectsState,
      action: PayloadAction<{ summary: ISummary }>
    ) {
      const { summary } = action.payload;
      state.currentProjectStatusSummary = summary;
      state.isLoadingSummary = false;
    },

    createProject(
      state: ProjectsState,
      action: PayloadAction<{ project: Project }>
    ) {
      const { project } = action.payload;
      state.projects = [...state.projects, project];
      state.newProject = project;
    },

    updateProject(
      state: ProjectsState,
      action: PayloadAction<{ project: Project }>
    ) {
      const { project } = action.payload;
      state.projects = state.projects.map((x) =>
        x.projectID === project.projectID ? project : x
      );
      state.currentProject = project;
    },

    deleteProject(
      state: ProjectsState,
      action: PayloadAction<{ projectId: number }>
    ) {
      const { projectId } = action.payload;
      state.projects = state.projects.filter((x) => x.projectID !== projectId);
    },

    isLoading(state: ProjectsState, action: PayloadAction) {
      state.isLoading = true;
      state.response = {
        data: {
          result: [],
          pageNumber: 0,
          totalPages: 0,
          totalRecords: 0
        }
      };
    },

    isLoadingSummary(state: ProjectsState, action: PayloadAction) {
      state.isLoadingSummary = true;
    },

    setIsLoadingRecentProject(
      state: ProjectsState,
      action: PayloadAction<{ value: boolean }>
    ) {
      const { value } = action.payload;
      state.isLoadingRecentProject = value;
    },
    reset(state: ProjectsState, action: PayloadAction<{}>) {
      state.currentProject = null;
      state.currentProjectSummary = null;
    }
  }
});

export const reducer = slice.reducer;

export const getProjectsByQueryRaw = (baseQuery): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getProjectsByQueryRaw(baseQuery);
  dispatch(slice.actions.setResponse({ response }));
  //dispatch(slice.actions.setRequest({baseQuery, programId, }));
};

export const getProjectsByProgramId = (
  baseQuery,
  programId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getProjectsByProgramId(baseQuery, programId);
  dispatch(slice.actions.setResponse({ response }));
  //dispatch(slice.actions.setRequest({baseQuery, programId, }));
};

export const getProjectsBySiteId = (baseQuery): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getProjectsBySiteId(baseQuery);
  dispatch(slice.actions.setResponse({ response }));
  //dispatch(slice.actions.setRequest({baseQuery, programId, }));
};

// old

export const getProjectByProjectId = (projectId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const project = await api.getProjectByProjectId(projectId);

  if (project) await project.loadAvatar();

  dispatch(slice.actions.setCurrentProject({ project }));
};

export const getProjectsByClientId = (clientId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const projects = await api.getProjectsByClientId(clientId);
  dispatch(slice.actions.setCurrentProjects({ projects }));
};

export const getProjectSummaryByProjectId = (
  projectId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoadingSummary());
  const summary = await api.getProjectSummaryByProjectId(projectId);
  dispatch(slice.actions.setCurrentProjectSummary({ summary }));
};

export const getProjectStatusSummaryByProgramId = (
  programId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoadingSummary());
  const summary = await api.getProjectStatusSummaryByProgramId(programId);
  dispatch(
    slice.actions.setCurrentProjectStatusSummary({
      summary
    })
  );
};

export const createProject = (project: Project): AppThunk => async (
  dispatch
) => {
  const _project = await api.createProject(project.serialize());
  dispatch(slice.actions.createProject({ project }));
};

export const updateProject = (project: Project): AppThunk => async (
  dispatch
) => {
  const updatedProject = await api.updateProject(project);
  dispatch(
    slice.actions.updateProject({
      project: new Project({
        ...project,
        ...updatedProject
      })
    })
  );
};

export const deleteProjectByProjectId = (projectId: number): AppThunk => async (
  dispatch
) => {
  await api.deleteProjectByProjectId(projectId);
  dispatch(slice.actions.deleteProject({ projectId }));
};

export const getRecentProjectByUserId = (userId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.setIsLoadingRecentProject({ value: true }));
  const project = await api.getRecentProjectByUserId(userId);

  if (project) {
    dispatch(slice.actions.setCurrentProject({ project }));
    dispatch(getProjectSummaryByProjectId(project.projectID));
  }
  dispatch(slice.actions.setIsLoadingRecentProject({ value: false }));
};

export const reset = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.reset({}));
};

export default slice;
