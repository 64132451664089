// REF: https://codesandbox.io/s/m6yve?file=/src/ActionColumns.tsx:413-589

// import autobind from "autobind-decorator";
import * as React from 'react';
import {
  Template,
  Plugin,
  TemplateConnector,
  Getter,
  Getters
} from '@devexpress/dx-react-core';
import {
  TableCell,
  IconButton,
  Tooltip,
  Menu,
  MenuItem
} from '@material-ui/core';
import {
  Table,
  VirtualTable,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui';
import { TableColumn } from '@devexpress/dx-react-grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DeleteForever, Message } from '@material-ui/icons';

const pluginDependencies = [{ name: 'Table' }];

export interface IMenuColumn {
  icon: React.ReactElement<any>;
  label?: string;
  onClick: (event: any, row: any) => void;
}
export interface IMenuColumnProps {
  menuActions: (row: any) => IMenuColumn[];
}

// @autobind
class MenuColumnBase extends React.PureComponent<IMenuColumnProps> {
  public static MENU_COLUMN_TYPE = Symbol('MENU_COLUMN');
  public render() {
    const { menuActions } = this.props;

    return (
      <Plugin name="MenuColumn" dependencies={pluginDependencies}>
        <Getter name="tableColumns" computed={this.computeColumns.bind(null)} />
        <Template
          name="tableCell"
          predicate={this.isMenuTableHeader.bind(null)}
        >
          {(params: any) => (
            <TemplateConnector>
              {(getters, actions) => {
                return <TableCell />;
              }}
            </TemplateConnector>
          )}
        </Template>
        <Template name="tableCell" predicate={this.isMenuTableCell.bind(null)}>
          {(params: any) => (
            <TemplateConnector>
              {(getters, actions) => (
                <MenuComponent
                  menuActions={menuActions}
                  row={params.tableRow.row}
                />
              )}
            </TemplateConnector>
          )}
        </Template>
      </Plugin>
    );
  }

  private computeColumns(getters: Getters) {
    const tableColumns = getters.tableColumns as TableColumn[];
    const columns = tableColumns.map((tableColumn) => {
      if (tableColumn.column.name !== 'menu') {
        return tableColumn;
      }

      return {
        ...tableColumn,
        type: MenuColumnBase.MENU_COLUMN_TYPE,
        width: 60
      };
    });

    return columns;
  }

  private isMenuTableCell(params: any) {
    if (
      (params.tableRow.type === Table.ROW_TYPE ||
        params.tableRow.type === VirtualTable.ROW_TYPE) &&
      params.tableColumn.type === MenuColumnBase.MENU_COLUMN_TYPE
    ) {
      return true;
    }
    return false;
  }
  private isMenuTableHeader(params: any) {
    if (
      params.tableRow.type === TableHeaderRow.ROW_TYPE &&
      params.tableColumn.type === MenuColumnBase.MENU_COLUMN_TYPE
    ) {
      return true;
    }
    return false;
  }
}

const MenuComponent = ({ menuActions, row }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!menuActions) return null;

  return (
    <TableCell align="right" key={`${row.id}-table-cell`}>
      <Tooltip title="Actions" key={`${row.id}-tool-tip`}>
        <IconButton
          size="small"
          aria-label={'action-menu'}
          style={{ verticalAlign: 'middle' }}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        key={`${row.id}-menu`}
        id="action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuActions(row).map((action) => (
          <MenuItem
            value={action.label}
            key={action.label}
            onClick={(event) => {
              handleClose();
              if (action.onClick) action.onClick(event, row);
            }}
          >
            {action.icon} &nbsp;
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </TableCell>
  );
};

export const MenuColumn: React.ComponentType<IMenuColumnProps> & {
  MENU_COLUMN_TYPE: symbol;
} = MenuColumnBase;
