import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { validationMessage } from 'src/utils/validations';
import { Link as RouterLink } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import useSettings from 'src/hooks/useSettings';
import clsx from 'clsx';
import {
  Button,
  Box,
  TextField,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
  Grid
} from '@material-ui/core';

import ErrorPanel from 'src/components/Forms/ErrorPanel';
import { useQuery } from 'src/hooks/useQuery';
import { submitError } from 'src/components/Forms/FormikHelper';
import Page from 'src/components/Page';

interface LoginProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  links: {
    marginTop: theme.spacing(2),
    '& > * + * ': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const Login: FC<LoginProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const { login } = useAuth() as any;
  const isMountedRef = useIsMountedRef();
  const { settings, saveSettings } = useSettings();
  const query = useQuery();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Page className={classes.root} title="Login">
      <Typography variant="h2">BVPort</Typography>
      <Typography variant="body1">
        Please enter your login information
      </Typography>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          email: settings.rememberMe,
          password: settings.debug ? 'smash22!' : '',
          rememberMe: Boolean(settings.rememberMe),
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required(validationMessage('Email Address')),
          password: Yup.string().max(255).required('Password is required')
        })}
        onSubmit={async (values, actions) => {
          try {
            await login(
              values.email,
              values.password,
              query.get('redirectUrl')
            );

            saveSettings({ rememberMe: '' });

            // check to see if use wants to be remembered
            if (values.rememberMe) {
              saveSettings({ rememberMe: values.email });
            }

            if (isMountedRef.current) {
              actions.setStatus({ success: true });
              actions.setSubmitting(false);
            }
          } catch (err) {
            if (isMountedRef.current) {
              submitError(err, actions, true);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Box mt={2} mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    name="password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="rememberMe"
                        checked={values.rememberMe}
                        onChange={() =>
                          setFieldValue('rememberMe', !values.rememberMe)
                        }
                      />
                    }
                    label="Remember Me"
                  />
                </Grid>
                <Grid item xs={12}>
                  <ErrorPanel errors={errors} />
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Log In
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
      <div className={classes.links}>
        <Link
          component={RouterLink}
          to="/forgot"
          variant="caption"
          color="primary"
        >
          Reset Password?
        </Link>
        <Link
          component={RouterLink}
          to="/register"
          variant="caption"
          color="primary"
        >
          Register New Account
        </Link>
      </div>
    </Page>
  );
};

Login.propTypes = {
  className: PropTypes.string
};

export default Login;
