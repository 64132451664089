import axios from 'axios';
import { queryHelper } from 'src/apis/dynamic-query-helper';
import { IPager } from 'src/components/Tables/@hooks/usePagination';
import { Levels } from 'src/types/Levels';
import urlBuilder from 'src/utils/urlBuilder';
import { IDocument } from '../@types/IDocument';
import { ACTIONS } from './actions';
import { IState, defaultState } from './state';
import FileSaver from 'file-saver';
import { Column } from 'src/components/Tables/@types/column';
import { Filter } from 'src/components/Tables/@hooks/useFilters';
import { Sorting } from '@devexpress/dx-react-grid';
import { IDocumentSummary } from 'src/types/IDocumentSummary';

export interface IController {
  state: IState;
  init: (state: IState) => IController;
  fetchDocuments: (passiveLoader?: boolean) => Promise<any>;
  fetchDocumentsSummary: () => Promise<any>;
  fetchDocumentsById: (id: number) => Promise<any>;
  downloadDocument: (document: IDocument) => void;
  deleteDocument: (document: IDocument) => Promise<any>;
  setSearchParams: (params: IDocumentParams) => IController;
  setPager: (params: IPager) => IController;
  cancelPendingRequests: () => void;
  destroy: () => void;
}

export interface IDocumentParams {
  activeRecord?: boolean;
  searchValue?: string;
  section?: Levels;
  clientId?: number;
  programId?: number;
  siteId?: number;
  projectId?: number;
  userId?: number;
  currentUserId?: number;
  visibleColumns?: Column[];
  filters?: Filter[];
  sorting?: Sorting[];
}

interface IDocumentQueryResponse {
  pageNumber: number;
  result: IDocument[];
  rowsPerPage: number;
  totalPages: number;
  totalRecords: number;
  userId: number;
}

export const controller = (dispatch: any, state: IState): IController => {
  return {
    state,
    init: function (state: IState): IController {
      // TODO: find beter way to merge
      this.state = {
        ...defaultState,
        params: {
          ...defaultState.params,
          ...state.params
        }
      };

      dispatch(ACTIONS.initialize(this.state));

      return this;
    },
    setSearchParams: function (params: IDocumentParams) {
      this.state = {
        ...this.state,
        params: {
          ...this.state.params,
          ...params
        }
      };

      dispatch(ACTIONS.setSearchParams(params));

      return this;
    },
    deleteDocument: (document: IDocument) => {
      return axios
        .post(urlBuilder('Document/DeleteDocument'), null, {
          params: {
            id: document.documentID
          }
        })
        .then((resp) => {
          dispatch(ACTIONS.deleteDocument(document));
          return resp.data;
        })
        .catch((error) => {
          console.log('error fetching consumed', error);
          return Promise.reject(error);
        });
    },
    setPager: function (pager: IPager) {
      dispatch(ACTIONS.setPagination(pager));

      this.state = {
        ...this.state,
        pagination: pager
      };

      return this;
    },
    downloadDocument: async function (document: IDocument) {
      this.cancelPendingRequests();

      const cancelTokenSource = axios.CancelToken.source();
      dispatch(ACTIONS.setCancelToken(cancelTokenSource));

      const response = await axios.get(urlBuilder('Document/GetDocumentFile'), {
        params: {
          documentId: document.documentID
        },
        responseType: 'arraybuffer',
        cancelToken: cancelTokenSource.token
      });

      FileSaver.saveAs(
        new Blob([response.data], {
          type: response.headers['content-type']
        }),
        document.fileName
      );
    },
    fetchDocumentsById: function (id: number) {
      this.cancelPendingRequests();

      const cancelTokenSource = axios.CancelToken.source();
      dispatch(ACTIONS.setCancelToken(cancelTokenSource));

      return axios.post<IDocumentQueryResponse>(
        urlBuilder('Document/GetDocumentsByDynamicQuery'),
        {
          pagination: false,
          rowsPerPage: 1,
          pageNumber: 1,
          where: [
            {
              andOr: '',
              items: [
                {
                  and: '',
                  field: 'documentId',
                  operator: '=',
                  value: `${id}`
                }
              ]
            }
          ]
        },
        {
          cancelToken: cancelTokenSource.token
        }
      );
    },
    fetchDocumentsSummary: function () {
      this.cancelPendingRequests();

      const cancelTokenSource = axios.CancelToken.source();
      dispatch(ACTIONS.setCancelToken(cancelTokenSource));

      const { params } = this.state;

      let LinkLevel = null;
      let LinkID = null;

      if (params.clientId) {
        LinkLevel = Levels.CLIENT;
        LinkID = params.clientId;
      }
      if (params.programId) {
        LinkLevel = Levels.PROGRAM;
        LinkID = params.programId;
      }

      if (params.siteId) {
        LinkLevel = Levels.SITE;
        LinkID = params.siteId;
      }
      if (params.projectId) {
        LinkLevel = Levels.PROJECT;
        LinkID = params.projectId;
      }

      if (!LinkLevel) return;

      return axios
        .get<IDocumentSummary[]>(
          urlBuilder('Client/GetDocumentSummaryByLevelID'),
          {
            params: {
              LinkLevel,
              LinkID
            },
            cancelToken: cancelTokenSource.token
          }
        )
        .then((resp) => {
          dispatch(ACTIONS.setDocumentsSummary(resp.data));
          return resp.data;
        })
        .catch((error) => {
          console.log('error fetching consumed', error);
          return Promise.reject(error);
        });
    },
    fetchDocuments: function (passiveLoader = false) {
      if (!passiveLoader) dispatch(ACTIONS.loading());

      const { params, pagination } = this.state;

      this.cancelPendingRequests();

      const cancelTokenSource = axios.CancelToken.source();
      dispatch(ACTIONS.setCancelToken(cancelTokenSource));

      let query = [];
      let queryParams = {};
      let url = 'Document/GetDocumentsByDynamicQuery';

      const buildDocumentQuery = (
        url: string,
        level: string,
        levelId: number,
        linkLevel: string
      ) => {
        return `${url}?level=${level}&levelId=${levelId}&linkLevel=${linkLevel}`;
      };

      if (params.activeRecord)
        query = queryHelper
          .getActiveRecord(params.activeRecord, query)
          .getQuery();

      if (params.clientId)
        url = buildDocumentQuery(
          'Document/getDocumentsByDynamicQueryAndLevel',
          Levels.CLIENT,
          params.clientId,
          params.section
        );

      if (params.programId) {
        url = buildDocumentQuery(
          'Document/getDocumentsByDynamicQueryAndLevel',
          Levels.PROGRAM,
          params.programId,
          params.section
        );
      }

      if (params.siteId) {
        url = buildDocumentQuery(
          'Document/getDocumentsByDynamicQueryAndLevel',
          Levels.SITE,
          params.siteId,
          params.section
        );
      }

      if (params.projectId)
        url = buildDocumentQuery(
          'Document/getDocumentsByDynamicQueryAndLevel',
          Levels.PROJECT,
          params.projectId,
          params.section
        );

      if (params.currentUserId)
        query = queryHelper
          .getDocument(params.userId, Levels.USER, query)
          .getQuery();

      if (params.filters.length > 0) {
        query = queryHelper.addFilters(params.filters, query).getQuery();
      }
      if (params.searchValue) {
        query = queryHelper
          .searchColumns(
            params.visibleColumns.filter((x) => !x.exclude),
            params.searchValue,
            query
          )
          .getQuery();
      }

      return axios
        .post<IDocumentQueryResponse>(
          urlBuilder(url),
          {
            userId: params.currentUserId || params.userId,
            pagination: true,
            rowsPerPage: pagination.limit,
            pageNumber: pagination.offset + 1,
            where: query,
            orderBy: params.sorting.map(({ columnName, direction }) => ({
              fieldName: columnName,
              orderType: direction
            }))
          },
          {
            params: queryParams,
            cancelToken: cancelTokenSource.token
          }
        )
        .then((resp) => {
          dispatch(
            ACTIONS.setDocuments(resp.data.result, resp.data.totalRecords)
          );
          return resp.data.result;
        })
        .catch((error) => {
          console.log('error fetching consumed', error);
          return Promise.reject(error);
        });
    },
    cancelPendingRequests: function () {
      this.state.apiCancelToken &&
        this.state.apiCancelToken.cancel(
          'Operation canceled due to new request.'
        );
    },
    destroy: function () {
      this.cancelPendingRequests();
      //dispatch(ACTIONS.setState(defaultState));
    }
  };
};
