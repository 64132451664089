export interface IWithFaker {
  faker: () => any;
}

export const withFaker = <T>(schema: T) => (o: T): T & IWithFaker => {
  return {
    ...o,
    faker() {
      Object.keys(schema).forEach((key) => (this[key] = schema[key]));
      return this;
    },
  };
};
