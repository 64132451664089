const columns = [
  {
    name: 'validation',
    title: ' ',
    default: true,
    width: 30
  },
  {
    name: 'filename',
    title: 'File Name',
    default: true,
    width: 250
  },
  {
    name: 'fileSize',
    title: 'File Size',
    default: true,
    width: 100
  },
  { name: 'description', title: 'Description *', default: true, width: 250 },
  {
    name: 'classification',
    title: 'Classification  *',
    default: true,
    width: 165
  },
  {
    name: 'assessmentType',
    title: 'Assessment Type  *',
    default: true,
    width: 185
  },
  {
    name: 'status',
    title: 'Status  *',
    default: true,
    width: 145
  },
  {
    name: 'active',
    title: 'Active',
    default: true,
    width: 75
  },
  {
    name: 'internal',
    title: 'Internal',
    default: true,
    width: 75
  },
  {
    name: 'menu',
    title: 'Menu',
    width: 75
  }
];

export default columns;
