import { createSlice, createSelector } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import * as api from 'src/apis/client';
import { ISummary } from 'src/types/ISummary';
import { Modes } from 'src/types/IForm';
import Client from 'src/models/Client';
interface ClientsState {
  newClient: Client;
  logo: string;

  client: Client;
  currentClient: Client;

  clients: Client[];
  currentClients: Client[];
  isLoading: boolean;

  currentClientSummary: ISummary;
  isLoadingSummary: boolean;

  response: any;
  request: any;
}

const initialState: ClientsState = {
  newClient: null,
  logo: null,

  client: null,
  currentClient: null,

  clients: [],
  currentClients: [],
  isLoading: false,

  currentClientSummary: null,
  isLoadingSummary: false,

  response: {
    data: {
      result: [],
      pageNumber: 0,
      totalPages: 0,
      totalRecords: 0
    }
  },
  request: {}
};

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setRequest(
      state: ClientsState,
      action: PayloadAction<{ request: any; id?: any }>
    ) {
      const { request } = action.payload;
      state.request = request;
      state.isLoading = false;
    },

    setResponse(state: ClientsState, action: PayloadAction<{ response }>) {
      const { response } = action.payload;
      state.response = response;
      state.isLoading = false;
    },

    getClient(state: ClientsState, action: PayloadAction<{ logo: string }>) {
      const { logo } = action.payload;
      state.logo = logo;
    },

    setClient(state: ClientsState, action: PayloadAction<{ client: Client }>) {
      const { client } = action.payload;
      state.client = client;
      state.isLoading = false;
    },

    setClients(
      state: ClientsState,
      action: PayloadAction<{ clients: Client[] }>
    ) {
      const { clients } = action.payload;
      state.clients = clients;
      state.isLoading = false;
    },

    setCurrentClient(
      state: ClientsState,
      action: PayloadAction<{ client: Client }>
    ) {
      const { client } = action.payload;
      state.currentClient = client;
      state.isLoading = false;
    },

    setCurrentClients(
      state: ClientsState,
      action: PayloadAction<{ clients: Client[] }>
    ) {
      const { clients } = action.payload;
      state.currentClients = clients;
      state.isLoading = false;
    },

    setCurrentClientSummary(
      state: ClientsState,
      action: PayloadAction<{ summary: ISummary }>
    ) {
      const { summary } = action.payload;
      state.currentClientSummary = summary;
      state.isLoadingSummary = false;
    },

    createClient(
      state: ClientsState,
      action: PayloadAction<{ client: Client }>
    ) {
      const { client } = action.payload;
      state.clients = [...state.clients, client];
      state.newClient = client;
    },

    updateClient(
      state: ClientsState,
      action: PayloadAction<{ client: Client }>
    ) {
      const { client } = action.payload;
      state.clients = state.clients.map((x) =>
        x.clientID === client.clientID ? client : x
      );

      state.currentClient = client;
    },

    deleteClient(
      state: ClientsState,
      action: PayloadAction<{ clientId: number }>
    ) {
      const { clientId } = action.payload;
      state.clients = state.clients.filter((x) => x.clientID !== clientId);
    },

    isLoading(state: ClientsState, action: PayloadAction) {
      state.response = {
        data: {
          result: [],
          pageNumber: 0,
          totalPages: 0,
          totalRecords: 0
        }
      };
      state.isLoading = true;
    },

    isLoadingSummary(state: ClientsState, action: PayloadAction) {
      state.isLoadingSummary = true;
    },

    reset(state: ClientsState, action: PayloadAction<{}>) {
      state.currentClient = null;
      state.currentClientSummary = null;
    }
  }
});

export const reducer = slice.reducer;

export const getClientsByQueryRaw = (baseQuery): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getClientsByQueryRaw(baseQuery);
  dispatch(slice.actions.setResponse({ response }));
  dispatch(slice.actions.setRequest({ request: baseQuery }));
};

export const getClientsByProgramId = (
  baseQuery,
  programId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getClientsByProgramId(baseQuery, programId);
  dispatch(slice.actions.setResponse({ response }));
  //dispatch(slice.actions.setRequest({baseQuery, programId, }));
};

export const getClientsBySiteId = (baseQuery, siteId): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getClientsBySiteId(baseQuery, siteId);
  dispatch(slice.actions.setResponse({ response }));
  //dispatch(slice.actions.setRequest({baseQuery, programId, }));
};

export const getClientsByProjectId = (
  baseQuery,
  projectId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getClientsByProjectId(baseQuery, projectId);
  dispatch(slice.actions.setResponse({ response }));
  //dispatch(slice.actions.setRequest({baseQuery, programId, }));
};

// old
//TODO: remove and move to context furture consideration
export const getClientByClientId = (clientId: number): AppThunk => async (
  dispatch,
  getState
) => {
  const { clients } = getState();
  let client = clients.clients.find((x) => x.clientID === clientId);

  if (!client) {
    client = await api.getClientByClientId(clientId);
  }

  if (client) await client.loadAvatar();

  dispatch(
    slice.actions.setCurrentClient({
      client
    })
  );
};

//TODO: remove and move to context furture consideration
export const getClientSummaryByClientId = (
  clientId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoadingSummary());
  const summary = await api.getClientSummaryByClientId(clientId);
  dispatch(slice.actions.setCurrentClientSummary({ summary }));
};

export const getClientsByUserId = (userId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const clients = await api.getClientsByUserId(userId);
  dispatch(slice.actions.setCurrentClients({ clients }));
};

export const createClient = (client: Client): AppThunk => async (dispatch) => {
  const clients = await api.createClient(client);
  dispatch(slice.actions.createClient({ client }));
};

export const updateClient = (client: Client): AppThunk => async (dispatch) => {
  const updatedClient = await api.updateClient(client);
  dispatch(
    slice.actions.updateClient({
      client: updatedClient
    })
  );
};

export const deleteClientByClientId = (clientId): AppThunk => async (
  dispatch
) => {
  await api.deleteClientByClientId(clientId);
  dispatch(slice.actions.deleteClient({ clientId }));
};

export const selectClients = (state: ClientsState) => state.clients;
export const selectActiveClients = createSelector([selectClients], (clients) =>
  clients.filter((x: Client) => x.active)
);
export const selectClientsByMode = (state: ClientsState, mode: Number) => {
  return createSelector(
    [selectClients, selectActiveClients],
    (clients, activeClients) => {
      if (mode === Modes.CREATE) {
        return activeClients;
      }

      return clients;
    }
  )(state);
};

export const reset = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.reset({}));
};

export default slice;
