import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { IUploadFile } from 'src/types/IUploadFile';
import { createUploadFile } from 'src/factory/UploadFile';

import { useDispatch, useSelector } from 'src/store';
import {
  getDocumentCategories,
  getAssessmentTypeList,
  getFileStatusList
} from 'src/slices/lookup';
import Can from 'src/components/Security/Can';
import useAuth from 'src/hooks/useAuth';

interface Props {
  [key: string]: any;
  onApplyClick?: (fileInfo: IUploadFile) => void;
  isDisabled?: boolean;
}

const BulkFileUpdateForm: FC<Props> = ({
  onApplyClick,
  isDisabled = false
}) => {
  const dispatch = useDispatch();
  const { user: loggedInUser } = useAuth();

  // slices
  const {
    documentCategories,
    assessmentTypeList,
    fileStatusList
  } = useSelector((state) => state.lookups);

  const [bulkFileInfo, setBulkFileInfo] = useState<IUploadFile>(
    createUploadFile()
  );

  useEffect(() => {
    dispatch(getDocumentCategories());
    dispatch(getAssessmentTypeList());
    dispatch(getFileStatusList());
  }, [getDocumentCategories, getAssessmentTypeList, getFileStatusList]);

  const handleApplyBulkClick = () => {
    if (onApplyClick) onApplyClick(createUploadFile(bulkFileInfo));
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h4">
                Bulk Update To Selected Files
              </Typography>
            </Grid>
            <Grid>
              <Button
                disabled={isDisabled}
                variant="contained"
                color="primary"
                onClick={handleApplyBulkClick}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={bulkFileInfo?.useFilename}
            label="Description"
            variant="outlined"
            fullWidth
            size="small"
            value={bulkFileInfo?.description ?? ''}
            onChange={(event) =>
              setBulkFileInfo({
                ...bulkFileInfo,
                description: event.target.value
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={Boolean(bulkFileInfo?.useFilename)}
                onChange={(event) =>
                  setBulkFileInfo({
                    ...bulkFileInfo,
                    description: '',
                    useFilename: event.target.checked
                  })
                }
                name="use-file-name"
              />
            }
            label="Use File Name for Description"
          />
        </Grid>

        <Can
          perform="documents:classification"
          yes={() => (
            <Grid item xs={2}>
              <Autocomplete
                options={documentCategories}
                value={
                  documentCategories.filter(
                    (x) => x.categoryName === bulkFileInfo.classification
                  )[0] || ''
                }
                onChange={(e: object, value: any | null) => {
                  setBulkFileInfo({
                    ...bulkFileInfo,
                    classification: value?.categoryName,
                    classificationId: value?.documentCategoryID
                  });
                }}
                fullWidth
                getOptionLabel={(option) => option.categoryName || ''}
                getOptionSelected={(option, value) =>
                  option.categoryName === value.categoryName
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    name="categoryName"
                    label="Classification"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
        />

        {!loggedInUser.isExternal && (
          <Grid item xs={2}>
            <Autocomplete
              options={assessmentTypeList}
              value={
                assessmentTypeList.filter(
                  (x) => x.assessmentTypeName === bulkFileInfo.assessmentType
                )[0] || ''
              }
              onChange={(e: object, value: any | null) => {
                setBulkFileInfo({
                  ...bulkFileInfo,
                  assessmentType: value?.assessmentTypeName,
                  assessmentTypeId: value?.assessmentTypeID
                });
              }}
              fullWidth
              getOptionLabel={(option) => option.assessmentTypeName || ''}
              getOptionSelected={(option, value) =>
                option.assessmentTypeName === value.assessmentTypeName
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="assessmentTypeName"
                  label="Assessment Type"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
        )}

        {!loggedInUser.isExternal && (
          <Grid item xs={2}>
            <Autocomplete
              options={fileStatusList}
              value={
                fileStatusList.filter(
                  (x) => x.fileStatusID === bulkFileInfo.statusId
                )[0] || ''
              }
              onChange={(e: object, value: any | null) => {
                setBulkFileInfo({
                  ...bulkFileInfo,
                  status: value?.name,
                  statusId: value?.fileStatusID
                });
              }}
              fullWidth
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) =>
                option.fileStatusID === value.fileStatusID
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="status"
                  label="Status"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={bulkFileInfo?.active}
                onChange={(event) =>
                  setBulkFileInfo({
                    ...bulkFileInfo,
                    active: event.target.checked
                  })
                }
                name="active"
              />
            }
            label="Active"
          />
        </Grid>

        <Can
          perform="documents:internal:only"
          yes={() => (
            <Grid item xs>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={Boolean(bulkFileInfo?.internal)}
                    onChange={(event) =>
                      setBulkFileInfo({
                        ...bulkFileInfo,
                        internal: event.target.checked
                      })
                    }
                    name="internal"
                  />
                }
                label="Internal"
              />
            </Grid>
          )}
        />

        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Remarks (optional)"
            size="small"
            multiline
            rows={3}
            value={bulkFileInfo?.comments ?? ''}
            onChange={(event) =>
              setBulkFileInfo({
                ...bulkFileInfo,
                comments: event.target.value
              })
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BulkFileUpdateForm;
