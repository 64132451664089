import IAction from 'src/types/IAction';
import { ACTION_TYPES } from './actions';
import { IState } from './state';

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case ACTION_TYPES.INITIALIZE:
      return {
        ...state,
        isInitialized: true,
        isLoading: false,
        isDirty: true,
        params: {
          ...state.params,
          ...action.payload.params
        }
      };
      break;

    case ACTION_TYPES.LOADING:
      return {
        ...state,
        isLoading: true
      };
      break;

    case ACTION_TYPES.SET_SEARCH_PARAMS:
      return {
        ...state,
        isDirty: true,
        params: {
          ...state.params,
          ...action.payload
        }
      };
      break;

    case ACTION_TYPES.SET_PROJECTS:
      const { projects, totalRecords } = action.payload;

      return {
        ...state,
        isDirty: false,
        isLoading: false,
        // apiCancelToken: null,
        projects,
        totalRecords
      };
      break;

    case ACTION_TYPES.DELETE_PROJECTS:
      return {
        ...state,
        isDirty: true,
        isLoading: false,
        projects: state.projects.filter(
          (x) => x.projectID !== action.payload.projectID
        )
      };
      break;

    case ACTION_TYPES.CANCEL_TOKEN_SOURCE:
      return {
        ...state,
        apiCancelToken: action.payload
      };
      break;

    case ACTION_TYPES.SET_PAGINATION:
      return {
        ...state,
        isDirty: true,
        pagination: action.payload
      };
      break;

    case ACTION_TYPES.SET_STATE:
      return {
        ...state,
        ...action.payload
      };
      break;

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }

  return state;
};

export default reducer;
