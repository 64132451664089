import axios from 'axios';
import { IDocument } from 'src/types/IDocument';
import { IFile } from 'src/types/IFile';
import urlBuilder from 'src/utils/urlBuilder';
import FileSaver from 'file-saver';
import { Levels } from 'src/types/Levels';
import { queryHelper } from './dynamic-query-helper';
import slice from 'src/slices/document';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

interface IDocumentQuery {
  pageNumber: number;
  result: IDocument[];
  rowsPerPage: number;
  totalPages: number;
  totalRecords: number;
  userId: number;
}

interface IQuery {
  userId?: number;
  pagination?: boolean;
  summaryOnly?: boolean;
  pageNumber?: number;
  where?: any[];
  rowsPerPage?: number;
  orderBy?: [
    {
      fieldName?: string;
      orderType?: string;
    }
  ];
}

export const getDocumentsByQueryRaw = async (query: any) => {
  const cancelTokenSource = axios.CancelToken.source();
  window['axiosCancel'] = cancelTokenSource;

  return await axios.post<IDocumentQuery>(
    urlBuilder('Document/GetDocumentsByDynamicQuery'),
    query,
    {
      cancelToken: cancelTokenSource.token
    }
  );
};

export const getDocumentsByDynamicQuery = async (
  query: any,
  limit?: number
) => {
  if (limit) {
    query = {
      ...query,
      rowsPerPage: limit
    };
  }

  const response = await axios.post<IDocumentQuery>(
    urlBuilder('Document/GetDocumentsByDynamicQuery'),
    query
  );

  return response.data.result;
};

export const getDocuments = async () => {
  const documents = await getDocumentsByDynamicQuery({});
  return documents;
};

export const getDocumentsByUserId = async (userId: number) => {
  const documents = await getDocumentsByDynamicQuery({
    userId,
    pagination: true,
    pageNumber: 1
  });

  return documents;
};

export const getDocumentsByProgramId = async (programId: number) => {
  const documents = await getDocumentsByDynamicQuery({
    where: queryHelper.getProgram(programId).getQuery()
  });
  return documents;
};

export const getDocumentsByProjectId = async (projectId: number) => {
  const documents = await getDocumentsByDynamicQuery({
    where: queryHelper.getProject(projectId).getQuery()
  });
  return documents;
};

export const getDocumentsBySiteId = async (siteId: number) => {
  const documents = await getDocumentsByDynamicQuery({
    where: queryHelper.getSite(siteId).getQuery()
  });
  return documents;
};

export const getRecentDocuments = async () => {
  const documents = await getDocumentsByDynamicQuery({
    orderBy: [
      {
        fieldName: 'createdDate',
        OrderType: 'desc'
      }
    ]
  });
  return documents;
};

//TODO remove later
export const getDocumentsByDocumentId = async (documentId: number) => {
  const documents = await getDocumentsByDynamicQuery({
    pagination: false,
    rowsPerPage: 1,
    pageNumber: 1,
    where: [
      {
        andOr: '',
        items: [
          {
            and: '',
            field: 'documentId',
            operator: '=',
            value: `${documentId}`
          }
        ]
      }
    ]
  });

  return documents[0] || null;
};

export const getDocumentsById = (id: number) => {
  return getDocumentsByDynamicQuery({
    pagination: false,
    rowsPerPage: 1,
    pageNumber: 1,
    where: [
      {
        andOr: '',
        items: [
          {
            and: '',
            field: 'documentId',
            operator: '=',
            value: `${id}`
          }
        ]
      }
    ]
  });
};

export const getDocumentFile = async (
  document: IDocument,
  setProgress?: React.Dispatch<React.SetStateAction<number>>
) => {
  const response = await axios.get(urlBuilder('Document/GetDocumentFile'), {
    params: {
      documentId: document.documentID
    },
    onDownloadProgress(progress) {
      const percentage = Math.round((progress.loaded * 100) / progress.total);
      setProgress(percentage);
    },
    responseType: 'arraybuffer'
  });

  FileSaver.saveAs(
    new Blob([response.data], {
      type: response.headers['content-type']
    }),
    document.fileName
  );
};

export const addDocument = async (
  id: number,
  document: IDocument,
  callback?: any
) => {
  const response = await axios.post<IDocument>(
    urlBuilder('Document/AddDocument'),
    {
      ...document,
      linkID: id
    }
  );

  return response.data;
};

export const postDocument = async (
  documentId,
  file: File,
  onUploadProgress?,
  callback?: any
) => {
  var formData = new FormData();
  formData.append('file', file);

  return axios.post<IDocument[]>(
    urlBuilder('Document/PostDocumentFile'),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        documentId
      },
      onUploadProgress
    }
  );
};

export const deleteDocument = async (document: IDocument) => {
  return await axios.post<IFile>(urlBuilder('Document/DeleteDocument'), null, {
    params: {
      id: document.documentID
    }
  });
};

export const deleteDocumentFile = async (document: IDocument) => {
  return await axios.post<IFile>(
    urlBuilder('Document/DeleteDocumentFile'),
    null,
    {
      params: {
        documentId: document.documentID
      }
    }
  );
};

export const updateDocument = async (id: number, document: IDocument) => {
  return await axios.post<IFile>(
    urlBuilder('Document/UpdateDocument'),
    {
      ...document,
      linkID: id
    },
    {
      params: {
        id: document.documentID
      }
    }
  );
};

export const downloadDocumentsAsZip = async (
  documentIds: number[],
  dispatch: ThunkDispatch<any, null, Action<string>>,
  setProgress?: React.Dispatch<React.SetStateAction<number>>
) => {
  const response = await axios.post(
    urlBuilder('Document/GetDocumentsAsaZipFile'),
    documentIds,
    {
      onDownloadProgress(progress) {
        const percentage = Math.round((progress.loaded * 100) / progress.total);
        setProgress(percentage);
      },
      responseType: 'arraybuffer'
    }
  );

  if (response.data) {
    FileSaver.saveAs(
      new Blob([response.data], { type: 'application/zip' }),
      'files.zip'
    );

    return true;
  }

  return false;
};

export const setAllDocumentCommentsToRead = async (
  documentId: number
): Promise<void> => {
  axios.post(urlBuilder('Document/SetAllDocumentCommentsToRead'), null, {
    params: {
      documentId
    }
  });
};
