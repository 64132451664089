import React, { createContext, useContext, useEffect } from 'react';
import type { ReactNode } from 'react';

import { reducer, IState, defaultState, controller } from './@state';
import { IController } from './@state/controller';
import { eventEmitter } from 'src/utils/reducer/middleware/eventEmitter';
import { useReducerMiddleware } from 'src/utils/reducer/middleware/useReducerMiddleware';
import { isObjectEmpty } from 'src/utils/object';
interface IContext {
  controller?: IController;
  state?: IState | null;
  ref?: any;
}

const UserAccessContext = createContext<IContext>({});

const useUserAccess = (): IContext => {
  const context = useContext(UserAccessContext);

  if (isObjectEmpty(context)) {
    throw new Error('useUserAccess must be used within a UserAccessProvider');
  }
  return context;
};

interface ProviderProps {
  children: ReactNode;
}

const UserAccessProvider: React.FC<ProviderProps> = ({
  children
}: ProviderProps) => {
  const [state, dispatch] = useReducerMiddleware<IState>(
    reducer,
    defaultState,
    [eventEmitter]
  );

  const _controller = controller(dispatch, state);

  return (
    <UserAccessContext.Provider value={{ state, controller: _controller }}>
      {children}
    </UserAccessContext.Provider>
  );
};

export { UserAccessProvider, useUserAccess };
