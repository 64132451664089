import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import { snackActions } from 'src/utils/SnackbarUtils';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, user, logout } = useAuth();
  const { pathname, search } = useLocation();

  useEffect(() => {
    const UNAUTHORIZED = 401;
    const BAD_REQUEST = 400;
    const TIMEOUT = 408;

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        try {
          const { status } = error?.response;

          if (status === UNAUTHORIZED) {
            logout();
            snackActions.error(
              'Your session has ended. To continue working please re-log back in.'
            );
            return Promise.reject();
          }

          if (status === TIMEOUT) {
            snackActions.error(error?.response);
            return Promise.reject();
          }

          return Promise.reject(error);
        } catch (err) {
          logout();
          return Promise.reject(err);
        }
      }
    );
  }, [axios]);

  let redirectUrl = '';

  if (pathname) redirectUrl = pathname;

  if (search) redirectUrl += search;

  if (!isAuthenticated) {
    return (
      <Redirect
        to={
          redirectUrl === ''
            ? '/login'
            : `/login?redirectUrl=${encodeURIComponent(redirectUrl)}`
        }
      />
    );
  }

  if (!user.acceptedTermsAndConditions) {
    return (
      <Redirect
        to={
          redirectUrl === ''
            ? '/updated-terms'
            : `/updated-terms?redirectUrl=${encodeURIComponent(redirectUrl)}`
        }
      />
    );
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
