import React, { useState } from 'react';
import type { FC } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  Badge,
  IconButton,
  SvgIcon,
  Tooltip,
  Box,
  makeStyles,
  Typography,
  Paper
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import Downloader from 'src/components/Downloader';
import useDownloadFiles from 'src/hooks/useDownloadFiles';
import DownloadInProgress from 'src/components/DownloadInProgress';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
    borderRadius: 0
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    borderRadius: 4,
    border: `1px solid`,
    top: theme.spacing(7),
    right: theme.spacing(30),
    zIndex: 1000
  }
}));

const Downloads: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { currentDownloads } = useDownloadFiles();

  const handleClick = async (): Promise<void> => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <Tooltip title="Downloads">
        <Badge
          color="primary"
          variant="standard"
          classes={{ badge: classes.badge }}
        >
          <IconButton color="inherit" onClick={handleClick}>
            {currentDownloads.length > 0 ? (
              <DownloadInProgress />
            ) : (
              <SvgIcon fontSize="small">
                <GetAppIcon />
              </SvgIcon>
            )}
          </IconButton>
        </Badge>
      </Tooltip>
      {open && (
        <Box className={classes.popover}>
          {currentDownloads.length > 0 ? (
            currentDownloads.map((item) => (
              <Downloader
                key={item.id}
                fileName={item.fileName}
                progress={item.progress}
              />
            ))
          ) : (
            <Paper className={classes.paper}>
              <Typography>No active downloads</Typography>
            </Paper>
          )}
        </Box>
      )}
    </>
  );
};

export default Downloads;
