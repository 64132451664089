import axios from 'axios';
import { queryHelper } from 'src/apis/dynamic-query-helper';
import { IPager } from 'src/components/Tables/@hooks/usePagination';
import { Levels } from 'src/types/Levels';
import urlBuilder from 'src/utils/urlBuilder';
import { IProgram } from '../@types/IProgram';
import { ACTIONS } from './actions';
import { defaultState, IState } from './state';
import { Column } from 'src/components/Tables/@types/column';
import { Filter } from 'src/components/Tables/@hooks/useFilters';
import { Sorting } from '@devexpress/dx-react-grid';

export interface IController {
  state: IState;
  init: (state: IState) => IController;
  fetchPrograms: () => Promise<any>;
  deleteProgram: (program: IProgram) => Promise<any>;
  setSearchParams: (params: IProgramParams) => IController;
  setPager: (params: IPager) => IController;
  cancelPendingRequests: () => void;
  destroy: () => void;
}

export interface IProgramParams {
  activeRecord?: boolean;
  searchValue?: string;
  clientId?: number;
  programId?: number;
  siteId?: number;
  projectId?: number;
  userId?: number;
  currentUserId?: number;
  visibleColumns?: Column[];
  filters?: Filter[];
  sorting?: Sorting[];
  showConfidentialOnly?: boolean;
  showRegularOnly?: boolean;
}

export interface IProgramQueryResponse {
  pageNumber: number;
  result: IProgram[];
  rowsPerPage: number;
  totalPages: number;
  totalRecords: number;
  userId: number;
}

export const controller = (dispatch: any, state: IState): IController => {
  return {
    state,
    init: function (state: IState): IController {
      // TODO: find beter way to merge
      this.state = {
        ...defaultState,
        params: {
          ...defaultState.params,
          ...state.params
        }
      };

      dispatch(ACTIONS.initialize(this.state));

      return this;
    },
    setSearchParams: function (params: IProgramParams) {
      this.state = {
        ...this.state,
        params: {
          ...this.state.params,
          ...params
        }
      };

      dispatch(ACTIONS.setSearchParams(params));

      return this;
    },
    deleteProgram: (program: IProgram) => {
      return axios
        .post(urlBuilder('Client/DeleteProgram'), null, {
          params: {
            id: program.programID
          }
        })
        .then((resp) => {
          dispatch(ACTIONS.deleteProgram(program));
          return resp.data;
        })
        .catch((error) => {
          console.log('error fetching consumed', error);
          return Promise.reject(error);
        });
    },
    setPager: function (pager: IPager) {
      dispatch(ACTIONS.setPagination(pager));

      this.state = {
        ...this.state,
        pagination: pager
      };

      return this;
    },
    fetchPrograms: function () {
      dispatch(ACTIONS.loading());
      const { params, pagination } = this.state;

      this.cancelPendingRequests();

      const cancelTokenSource = axios.CancelToken.source();
      dispatch(ACTIONS.setCancelToken(cancelTokenSource));

      let query = [];

      if (params.activeRecord)
        query = queryHelper
          .getActiveRecord(params.activeRecord, query)
          .getQuery();

      if (params.clientId)
        query = queryHelper.getClient(params.clientId, query).getQuery();

      if (params.showConfidentialOnly)
        query = queryHelper.getConfidential(true, query).getQuery();

      if (params.showRegularOnly) {
        query = queryHelper.getConfidential(false, query).getQuery();
      }

      if (params.filters.length > 0) {
        query = queryHelper.addFilters(params.filters, query).getQuery();
      }
      if (params.searchValue) {
        query = queryHelper
          .searchColumns(
            params.visibleColumns.filter((x) => !x.exclude),
            params.searchValue,
            query
          )
          .getQuery();
      }

      const baseQuery = {
        userId: params.currentUserId || params.userId,
        pagination: true,
        rowsPerPage: pagination.limit,
        pageNumber: pagination.offset + 1,
        where: query,
        orderBy: params.sorting.map(({ columnName, direction }) => ({
          fieldName: columnName,
          orderType: direction
        }))
      };

      if (params.siteId) {
        return axios
          .post<IProgramQueryResponse>(
            urlBuilder('Client/GetProgramsBySiteId'),
            baseQuery,
            {
              params: {
                siteId: params.siteId
              },
              cancelToken: cancelTokenSource.token
            }
          )
          .then((resp) => {
            dispatch(
              ACTIONS.setPrograms(resp.data.result, resp.data.totalRecords)
            );
            return resp.data.result;
          })
          .catch((error) => {
            console.log('error fetching consumed', error);
            return Promise.reject(error);
          });
      }

      if (params.projectId) {
        return axios
          .post<IProgramQueryResponse>(
            urlBuilder('Client/GetProgramsByProjectId'),
            baseQuery,
            {
              params: {
                projectId: params.projectId
              },
              cancelToken: cancelTokenSource.token
            }
          )
          .then((resp) => {
            dispatch(
              ACTIONS.setPrograms(resp.data.result, resp.data.totalRecords)
            );
            return resp.data.result;
          })
          .catch((error) => {
            console.log('error fetching consumed', error);
            return Promise.reject(error);
          });
      }

      return axios
        .post<IProgramQueryResponse>(
          urlBuilder('Client/GetProgramsByDynamicQuery'),
          baseQuery,
          { cancelToken: cancelTokenSource.token }
        )
        .then((resp) => {
          dispatch(
            ACTIONS.setPrograms(resp.data.result, resp.data.totalRecords)
          );
          return resp.data.result;
        })
        .catch((error) => {
          console.log('error fetching consumed', error);
          return Promise.reject(error);
        });
    },
    cancelPendingRequests: function () {
      console.log('apiCancelToken', this.state.apiCancelToken);

      this.state.apiCancelToken &&
        this.state.apiCancelToken.cancel(
          'Operation canceled due to new request.'
        );
    },
    destroy: function () {
      this.cancelPendingRequests();
      //dispatch(ACTIONS.setState(defaultState));
    }
  };
};
