import React, { useEffect, useState } from 'react';
import type { FC } from 'react';

import { makeStyles, Card, Backdrop, Box } from '@material-ui/core';
import type { Theme } from 'src/theme';
import { useDispatch, useSelector } from 'src/store';
import { currentFile, currentFiles, closeModal } from 'src/slices/document';
import UploadFileForm, { UploadFileFormModes } from './UploadFileForm';
import { addDocument, postDocument } from 'src/apis/document';
import { IDocument } from 'src/types/IDocument';
import {
  submitError,
  submitSuccess
} from '../../../components/Forms/FormikHelper';
import * as slices from 'src/slices/document';
import { Levels } from 'src/types/Levels';
import Emitter from 'src/services/emitter';
import useAuth from 'src/hooks/useAuth';
import MultiUploadForm from './MultiUploadForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff'
  },
  card: {
    width: ({ isMultiUploadForm }: { isMultiUploadForm: boolean }) =>
      isMultiUploadForm ? '95%' : '50%',
    maxHeight: ({ isMultiUploadForm }: { isMultiUploadForm: boolean }) =>
      isMultiUploadForm ? '95%' : '75vh',
    overflow: 'auto',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none'
  }
}));

const FileManager: FC = () => {
  const isMultiUploadForm = currentFiles?.length > 0;

  const classes = useStyles({
    isMultiUploadForm
  });
  const dispatch = useDispatch();

  const { user } = useAuth();

  const { currentClient } = useSelector((state) => state.clients);
  const { currentProgram } = useSelector((state) => state.programs);
  const { currentSite } = useSelector((state) => state.sites);
  const { currentProject } = useSelector((state) => state.projects);
  const { currentUser } = useSelector((state) => state.users);

  const { isModalOpen, currentDocument, level } = useSelector(
    (state) => state.documents
  );

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleSubmitUpdate = async (values, actions) => {
    try {
      const { user, ...parsedValues } = values;
      const updateDocument = {
        ...currentDocument,
        ...parsedValues
      };

      dispatch(
        slices.updateDocument(values.id, updateDocument, () =>
          Emitter.emit(emitterId, {
            type: 'UPDATE_DOCUMENT',
            payload: updateDocument
          })
        )
      );

      submitSuccess('Updated File', actions);

      setDocumentTemplate({});
      dispatch(closeModal());
    } catch (err) {
      submitError(err, actions);
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      Emitter.emit('APP', {
        action: 'LOADER',
        isLoading: true,
        message: 'Uploading file, please be patient.'
      });

      const document = await addDocument(values.id, values as IDocument);

      await postDocument(document.documentID, currentFile);

      Emitter.emit(emitterId, {
        type: 'ADD_DOCUMENT',
        payload: document
      });

      dispatch(slices.addDocument(document));

      submitSuccess('File Uploaded', actions);

      Emitter.emit('APP', {
        action: 'LOADER',
        isLoading: false
      });

      //dispatch(getDocumentsByQueryRaw(request.baseQuery));
      setDocumentTemplate({});

      dispatch(closeModal());
    } catch (err) {
      submitError(err, actions);
    }
  };

  const [documentTemplate, setDocumentTemplate] = useState({});

  useEffect(() => {
    if (level) {
      switch (level) {
        case Levels.CLIENT:
          setDocumentTemplate({
            clientName: currentClient.clientName,
            clientID: currentClient.clientID,
            id: currentClient.clientID,
            linkLevel: level
          });
          break;

        case Levels.PROGRAM:
          setDocumentTemplate({
            clientName: currentProgram.clientName,
            clientID: currentProgram.clientID,
            programID: currentProgram.programID,
            id: currentProgram.programID,
            programName: currentProgram.programName,
            linkLevel: level
          });
          break;

        case Levels.SITE:
          setDocumentTemplate({
            clientName: currentSite.clientName,
            clientID: currentSite.clientID,
            siteID: currentSite.siteID,
            id: currentSite.siteID,
            siteName: currentSite.siteName,
            linkLevel: level
          });
          break;

        case Levels.PROJECT:
          setDocumentTemplate({
            clientName: currentProject.clientName,
            clientID: currentProject.clientID,
            projectID: currentProject.projectID,
            id: currentProject.projectID,
            projectName: currentProject.projectName,
            linkLevel: level
          });
          break;

        case Levels.USER:
          setDocumentTemplate({
            clientName: currentUser.clientName,
            clientID: currentUser.clientID,
            id: currentUser.userID,
            linkLevel: level
          });
          break;

        default:
          setDocumentTemplate({});
      }
    }
  }, [
    level,
    currentClient,
    currentProgram,
    currentSite,
    currentUser,
    currentProject,
    currentDocument
  ]);

  if (!isModalOpen) return null;

  return (
    <Backdrop
      className={classes.backdrop}
      open={isModalOpen}
      onClick={() => {}}
    >
      <Card className={classes.card} onClick={(e) => e.stopPropagation()}>
        <Box m={2}>
          {currentFile && (
            <UploadFileForm
              mode={
                user.isReadOnly
                  ? UploadFileFormModes.READ_ONLY
                  : UploadFileFormModes.CREATE
              }
              file={currentFile}
              document={documentTemplate}
              onSubmit={handleSubmit}
              onClose={handleClose}
            />
          )}
          {currentDocument && (
            <UploadFileForm
              mode={
                user.isReadOnly
                  ? UploadFileFormModes.READ_ONLY
                  : UploadFileFormModes.EDIT
              }
              document={{ ...currentDocument, id: currentDocument.linkID }}
              onSubmit={handleSubmitUpdate}
              onClose={handleClose}
            />
          )}
          {currentFiles && (
            <MultiUploadForm
              systemFiles={currentFiles}
              document={documentTemplate}
              onClose={handleClose}
            />
          )}
        </Box>
      </Card>
    </Backdrop>
  );
};

export const emitterId = 'FILE_MANAGER_ACTION';

export default FileManager;
