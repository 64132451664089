import React, { createContext, useCallback, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';

interface DownloadFile {
  id: string;
  fileName: string;
  progress: number;
}

interface DownloadFileContextValue {
  currentDownloads?: DownloadFile[];
  updateDownloadFileProgress?: (downloadFile: DownloadFile) => void;
  generateRandomId: () => string;
}

const DownloadFilesContext = createContext<DownloadFileContextValue>({
  currentDownloads: [],
  updateDownloadFileProgress: () => {},
  generateRandomId: () => '',
});

interface DownloadFileProviderProps {
  children?: ReactNode;
}

export const DownloadFilesProvider: FC<DownloadFileProviderProps> = ({
  children
}) => {
  const [currentDownloads, setCurrentDownloads] = useState<DownloadFile[]>([]);
  const updateDownloadFileProgress = (downloadFile: DownloadFile) => {
    const isIncluded = currentDownloads.some(item => item.id === downloadFile.id);
    let newCurrentDownloads = [];

    if (isIncluded) {
      newCurrentDownloads = currentDownloads.map((d) => {
        if (String(d.id) === String(downloadFile.id)) {
          return {
            ...d,
            ...downloadFile
          };
        }
        return d;
      })
    } else {
      newCurrentDownloads = [...currentDownloads, downloadFile];
    }

    if (newCurrentDownloads.length > 0) {
      setCurrentDownloads(newCurrentDownloads);
    }
  };

  useEffect(() => {
    const hasSomeFinishedDownloading = currentDownloads.some(
      (item) => item.progress >= 100
    );
    if (hasSomeFinishedDownloading) {
      setCurrentDownloads(
        currentDownloads.filter((item) => item.progress < 100)
      );
    }
  }, [currentDownloads]);

  const generateRandomId = useCallback(() => Date.now().toString(), []);

  return (
    <DownloadFilesContext.Provider
      value={{
        updateDownloadFileProgress,
        generateRandomId,
        currentDownloads
      }}
    >
      {children}
    </DownloadFilesContext.Provider>
  );
};

export const DownloadFilesConsumer = DownloadFilesContext.Consumer;
export default DownloadFilesContext;
