import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Radio,
  Grid,
  TextField,
  FormControlLabel,
  RadioGroup,
  Typography,
  Divider,
  FormLabel,
  Checkbox,
  makeStyles,
  IconButton
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'src/store';
import {
  getDocumentCategories,
  getAssessmentTypeList,
  getFileStatusList
} from 'src/slices/lookup';
import {} from 'src/slices/lookup';
import ErrorPanel from '../../../components/Forms/ErrorPanel';
import useAuth from 'src/hooks/useAuth';
import { IDocument } from 'src/types/IDocument';
import Can from 'src/components/Security/Can';
import ClientAutoComplete from '../../../components/Common/Forms/ClientAutoComplete';
import { validationMessage } from 'src/utils/validations';
import { capitalize } from 'src/utils/string';
import { Levels } from 'src/types/Levels';
import ProgramAutoComplete from '../../../components/Common/Forms/ProgramAutoComplete';
import SiteAutoComplete from '../../../components/Common/Forms/SiteAutoComplete';
import ProjectAutoComplete from '../../../components/Common/Forms/ProjectAutoComplete';
import FileIcon from '../../../components/FileIcon';
import { queryHelper } from 'src/apis/dynamic-query-helper';
import fileSize from 'src/utils/fileSize';
const useStyles = makeStyles(() => ({
  gridUpload: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  avatar: {
    height: 40,
    width: 40
  },
  uploadTo: {
    paddingTop: 10,
    paddingRight: 20
  }
}));

export enum UploadFileFormModes {
  CREATE,
  EDIT,
  READ_ONLY
}
interface Props {
  [key: string]: any;
  file?: File;
  document?: IDocument;
}

const UploadFileForm: FC<Props> = ({
  file,
  className,
  site,
  document,
  onSubmit,
  onClose,
  onReplaceFile,
  mode = UploadFileFormModes.READ_ONLY,
  ...rest
}) => {
  const classes = useStyles();
  const isEditable = mode !== UploadFileFormModes.READ_ONLY;
  const dispatch = useDispatch();
  const { user } = useAuth();

  // slices
  const {
    documentCategories,
    assessmentTypeList,
    fileStatusList
  } = useSelector((state) => state.lookups);

  // state
  const [location, setLocation] = useState(Levels.CLIENT);
  const [clientId, setClientId] = useState<number>();
  const { user: loggedInUser } = useAuth();
  const [clientName, setClientName] = useState(null);

  useEffect(() => {
    dispatch(getDocumentCategories());
    dispatch(getAssessmentTypeList());
    dispatch(getFileStatusList());
  }, [getDocumentCategories, getAssessmentTypeList, getFileStatusList]);

  useEffect(() => {
    if (document) {
      const _level =
        (capitalize(document?.linkLevel) as Levels) || Levels.CLIENT;

      setClientId(document?.clientID);

      if (_level !== Levels.CLIENT && _level !== Levels.USER) {
        setClientName(document?.clientName);
      }

      setLocation(_level);
    }
  }, [document]);

  const clearLocations = (level: Levels, setFieldValue) => {
    setClientName(null);

    if (level === Levels.CLIENT || level === Levels.USER) {
      setFieldValue('programName', null);
      setFieldValue('programID', null);
      setFieldValue('siteName', null);
      setFieldValue('siteID', null);
      setFieldValue('projectName', null);
      setFieldValue('projectID', null);
    }

    if (level === Levels.PROGRAM) {
      setFieldValue('siteName', null);
      setFieldValue('siteID', null);
      setFieldValue('projectName', null);
      setFieldValue('projectID', null);
    }

    if (level === Levels.SITE) {
      setFieldValue('programName', null);
      setFieldValue('programID', null);
      setFieldValue('projectName', null);
      setFieldValue('projectID', null);
    }

    if (level === Levels.PROJECT) {
      setFieldValue('programName', null);
      setFieldValue('programID', null);
      setFieldValue('siteName', null);
      setFieldValue('siteID', null);
    }
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      initialValues={{
        clientName: document?.clientName,
        clientID: document?.clientID,
        programName: document?.programName,
        programID: document?.programID,
        siteName: document?.siteName,
        siteID: document?.siteID,
        projectName: document?.projectName,
        projectID: document?.projectID,
        description: document?.description,
        categoryName: document?.categoryName,
        accessType: document?.accessType,
        active: document?.active !== undefined ? document?.active : true,
        assessmentTypeName:
          document?.assessmentType || document?.assessmentTypeName,
        documentCategoryID:
          document?.documentCategoryID || (loggedInUser.isExternal ? 13 : 0), //TODO: this should be done on server side, for now if empty send 13 witch is extrnal
        assessmentTypeID:
          document?.assessmentTypeID || (loggedInUser.isExternal ? 33 : 0),
        fileStatusID: document?.fileStatusID || (loggedInUser.isExternal && 1),
        internal: document?.internal,
        notes: document?.notes,
        linkLevel: document?.linkLevel || Levels.CLIENT,
        user,
        id: document?.id || 0,

        submit: null
      }}
      validationSchema={Yup.object().shape({
        clientName: Yup.string()
          .nullable()
          .when('linkLevel', {
            is: (value) => value === Levels.CLIENT,
            then: Yup.string().required(validationMessage('Client Name'))
          }),
        categoryName: Yup.string().when('user', {
          is: (user) => user.isInternal,
          then: Yup.string().required(validationMessage('Classification'))
        }),
        programName: Yup.string()
          .nullable()
          .when('linkLevel', {
            is: (value) => value === Levels.PROGRAM,
            then: Yup.string().required(validationMessage('Program Name'))
          }),
        siteName: Yup.string()
          .nullable()
          .when('linkLevel', {
            is: (value) => value === Levels.SITE,
            then: Yup.string().required(validationMessage('Site Name'))
          }),
        projectName: Yup.string()
          .nullable()
          .when('linkLevel', {
            is: (value) => value === Levels.PROJECT,
            then: Yup.string().required(validationMessage('Project Name'))
          }),
        description: Yup.string().required(validationMessage('Description')),
        fileStatusID: !loggedInUser.isExternal
          ? Yup.number()
              .typeError('File Status is required')
              .required(validationMessage('File Status'))
          : Yup.number(),
        assessmentTypeName: !loggedInUser.isExternal
          ? Yup.string().required(validationMessage('Assessment Type'))
          : Yup.string()
      })}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography
                  title="Site Info"
                  variant="h3"
                  style={{ width: '100%' }}
                >
                  {mode === UploadFileFormModes.EDIT && 'Update File'}
                  {mode === UploadFileFormModes.CREATE && 'Upload File'}
                  {mode === UploadFileFormModes.READ_ONLY && 'File'}
                </Typography>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider />
              <Box mt={2}>
                <Grid container spacing={4}>
                  <Grid item xs={12} className={classes.gridUpload}>
                    <Box display="flex" justifyContent="row">
                      <FileIcon file={file?.name} width={48} height={48} />

                      <Box px={2}>
                        {mode === UploadFileFormModes.CREATE && (
                          <>
                            <Typography variant="h4">{file?.name}</Typography>
                            <Typography variant="h4">
                              {fileSize(file?.size, true)}
                            </Typography>
                          </>
                        )}
                        {[
                          UploadFileFormModes.EDIT,
                          UploadFileFormModes.READ_ONLY
                        ].includes(mode) && (
                          <>
                            <Typography variant="h4">
                              {document?.fileName}
                            </Typography>
                            <Typography variant="h4">
                              {fileSize(document?.documentSize)}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Description"
                      name="description"
                      value={values.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={!isEditable}
                      variant="outlined"
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={values?.active}
                          onChange={() =>
                            setFieldValue('active', !values.active)
                          }
                          name="active"
                          disabled={!isEditable}
                        />
                      }
                      label="Active"
                    />

                    <Can
                      data={{
                        userId: loggedInUser.userID,
                        currentUserId: user.userID
                      }}
                      perform="documents:internal:only"
                      yes={() => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={Boolean(values?.internal)}
                              onChange={() =>
                                setFieldValue('internal', !values?.internal)
                              }
                              name="internal"
                              disabled={!isEditable}
                            />
                          }
                          label="Internal Only"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RadioGroup
                      row
                      onChange={(e) => {
                        setFieldValue('linkLevel', e.target.value);
                        setLocation(e.target.value as Levels);

                        if (e.target.value === Levels.PROGRAM) {
                          clearLocations(Levels.PROGRAM, setFieldValue);
                          setFieldValue('id', values?.programID);
                        }

                        if (e.target.value === Levels.SITE) {
                          clearLocations(Levels.SITE, setFieldValue);
                          setFieldValue('id', values?.siteID);
                        }

                        if (e.target.value === Levels.PROJECT) {
                          clearLocations(Levels.PROJECT, setFieldValue);
                          setFieldValue('id', values?.projectID);
                        }

                        if (e.target.value === Levels.USER) {
                          clearLocations(Levels.USER, setFieldValue);
                          setFieldValue('id', user.userID);
                        }

                        if (e.target.value === Levels.CLIENT) {
                          clearLocations(Levels.CLIENT, setFieldValue);
                          setFieldValue('id', clientId);
                        }
                      }}
                      value={values.linkLevel}
                    >
                      <FormLabel
                        component="legend"
                        className={classes.uploadTo}
                      >
                        Upload to Location:
                      </FormLabel>
                      <FormControlLabel
                        disabled={!isEditable}
                        value={Levels.CLIENT}
                        control={<Radio color="primary" />}
                        label="Client"
                      />
                      <FormControlLabel
                        disabled={!isEditable}
                        value={Levels.PROGRAM}
                        control={<Radio color="primary" />}
                        label="Program"
                      />
                      <FormControlLabel
                        disabled={!isEditable}
                        value={Levels.SITE}
                        control={<Radio color="primary" />}
                        label="Site"
                      />
                      <FormControlLabel
                        disabled={!isEditable}
                        value={Levels.PROJECT}
                        control={<Radio color="primary" />}
                        label="Project"
                      />
                      {loggedInUser.isAdmin && (
                        <FormControlLabel
                          disabled={!isEditable}
                          value={Levels.USER}
                          control={<Radio color="primary" />}
                          label="User"
                        />
                      )}
                    </RadioGroup>
                  </Grid>
                  {values.linkLevel === Levels.PROGRAM && (
                    <Grid item xs={12}>
                      <ProgramAutoComplete
                        disabled={!isEditable}
                        extraQuery={queryHelper.getActiveRecord().getQuery()}
                        required
                        programId={values.programID}
                        onBlur={handleBlur}
                        onChange={(e: object, value: any | null) => {
                          clearLocations(Levels.PROGRAM, setFieldValue);
                          setFieldValue('programName', value?.programName);
                          setFieldValue('programID', value?.programID);
                          setFieldValue('id', value?.programID);
                          setClientName(value?.clientName);
                        }}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                  )}
                  {values.linkLevel === Levels.SITE && (
                    <Grid item xs={12}>
                      <SiteAutoComplete
                        disabled={!isEditable}
                        extraQuery={queryHelper
                          .getActiveRecord()

                          .getQuery()}
                        required
                        siteId={values.siteID}
                        onBlur={handleBlur}
                        onChange={(e: object, value: any | null) => {
                          clearLocations(Levels.SITE, setFieldValue);
                          setFieldValue('siteName', value?.siteName);
                          setFieldValue('siteID', value?.siteID);
                          setFieldValue('id', value?.siteID);
                          setClientName(value?.clientName);
                        }}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                  )}
                  {values.linkLevel === Levels.PROJECT && (
                    <Grid item xs={12}>
                      <ProjectAutoComplete
                        disabled={!isEditable}
                        extraQuery={queryHelper.getActiveRecord().getQuery()}
                        required
                        projectId={values.projectID}
                        onBlur={handleBlur}
                        onChange={(e: object, value: any | null) => {
                          clearLocations(Levels.PROJECT, setFieldValue);
                          setFieldValue('projectName', value?.projectName);
                          setFieldValue('projectID', value?.projectID);
                          setFieldValue('id', value?.projectID);
                          setClientName(value?.clientName);
                        }}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                  )}

                  {values.linkLevel === Levels.CLIENT && (
                    <Grid item xs={12}>
                      <ClientAutoComplete
                        disabled={!isEditable}
                        extraQuery={queryHelper.getActiveRecord().getQuery()}
                        required
                        clientId={values.clientID}
                        onBlur={handleBlur}
                        onChange={(e: object, value: any | null) => {
                          clearLocations(Levels.CLIENT, setFieldValue);
                          setFieldValue('clientName', value?.clientName);
                          setFieldValue('clientID', value?.clientID);
                          setFieldValue('id', value?.clientID);
                          setClientId(value?.clientID);
                          setFieldValue('linkLevel', Levels.CLIENT);
                          setLocation(Levels.CLIENT);
                        }}
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                  )}

                  {clientName && (
                    <Grid item xs>
                      <TextField
                        fullWidth
                        label="Client Name"
                        value={clientName}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                  )}

                  <Can
                    perform="documents:classification"
                    yes={() => (
                      <Grid item xs={12}>
                        <Autocomplete
                          disabled={!isEditable}
                          options={documentCategories}
                          value={
                            documentCategories.filter(
                              (x) => x.categoryName === values.categoryName
                            )[0] || ''
                          }
                          onBlur={handleBlur}
                          onChange={(e: object, value: any | null) => {
                            setFieldValue('categoryName', value?.categoryName);
                            setFieldValue(
                              'documentCategoryID',
                              value?.documentCategoryID
                            );
                          }}
                          fullWidth
                          getOptionLabel={(option) => option.categoryName || ''}
                          getOptionSelected={(option, value) =>
                            option.categoryName === value.categoryName
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(
                                touched.categoryName && errors.categoryName
                              )}
                              helperText={
                                touched.categoryName && errors.categoryName
                              }
                              required
                              name="categoryName"
                              label="Classification"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    )}
                  />

                  {!loggedInUser.isExternal && (
                    <Grid item xs={12}>
                      <Autocomplete
                        disabled={!isEditable}
                        options={fileStatusList}
                        value={
                          fileStatusList.filter(
                            (x) => x.fileStatusID === values.fileStatusID
                          )[0] || ''
                        }
                        onBlur={handleBlur}
                        onChange={(e: object, value: any | null) => {
                          setFieldValue('fileStatusID', value?.fileStatusID);
                        }}
                        fullWidth
                        getOptionLabel={(option) => option.name || ''}
                        getOptionSelected={(option, value) =>
                          option.fileStatusID === value.fileStatusID
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched.fileStatusID && errors.fileStatusID
                            )}
                            helperText={
                              touched.fileStatusID && errors.fileStatusID
                            }
                            required={!loggedInUser.isExternal}
                            name="fileStatusID"
                            label="File Status"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {!loggedInUser.isExternal && (
                    <Grid item xs={12}>
                      <Autocomplete
                        disabled={!isEditable}
                        options={assessmentTypeList}
                        value={
                          assessmentTypeList.filter(
                            (x) =>
                              x.assessmentTypeName === values.assessmentTypeName
                          )[0] || ''
                        }
                        onBlur={handleBlur}
                        onChange={(e: object, value: any | null) => {
                          setFieldValue(
                            'assessmentTypeName',
                            value?.assessmentTypeName
                          );
                          setFieldValue(
                            'assessmentTypeID',
                            value?.assessmentTypeID
                          );
                        }}
                        fullWidth
                        getOptionLabel={(option) =>
                          option.assessmentTypeName || ''
                        }
                        getOptionSelected={(option, value) =>
                          option.assessmentTypeName === value.assessmentTypeName
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched.assessmentTypeName &&
                                errors.assessmentTypeName
                            )}
                            helperText={
                              touched.assessmentTypeName &&
                              errors.assessmentTypeName
                            }
                            required
                            name="assessmentTypeName"
                            label="Assessment Type"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                      Remarks
                    </Typography>
                    <TextField
                      fullWidth
                      label="Notes (optional)"
                      name="notes"
                      value={values.notes}
                      multiline
                      rows={3}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={!isEditable}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Please note the maximum size per file is 100MB
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <ErrorPanel
                      errors={errors}
                      values={values}
                      touched={touched}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box display="flex">
                <Box flex={1}>
                  <Button onClick={onClose}>Cancel</Button>
                </Box>
                {mode !== UploadFileFormModes.READ_ONLY && (
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                    {mode === UploadFileFormModes.CREATE
                      ? 'Upload File'
                      : 'Update File'}
                  </Button>
                )}
              </Box>
            </>
          </form>
        );
      }}
    </Formik>
  );
};

export default UploadFileForm;
