import type { CancelTokenSource } from 'axios';
import { IPager } from 'src/components/Tables/@hooks/usePagination';
import { IDocumentSummary } from 'src/types/IDocumentSummary';
import { Levels } from 'src/types/Levels';
import type { IDocument } from '../@types/IDocument';
import type { IDocumentParams } from './controller';

export interface IState {
  isDirty?: boolean;
  isLoading?: boolean;
  isInitialized?: boolean;
  apiCancelToken?: CancelTokenSource;
  documents?: IDocument[];
  documentsSummary?: IDocumentSummary[];
  params?: IDocumentParams;
  pagination?: IPager;
  totalRecords?: number;
}

export const defaultState: IState = {
  isDirty: false,
  isLoading: false,
  isInitialized: false,
  documents: [],
  documentsSummary: [],
  params: {
    activeRecord: true,
    searchValue: '',
    visibleColumns: [],
    filters: [],
    sorting: [],
    section: Levels.ALL
  },
  pagination: {
    offset: 0,
    limit: 25
  },
  totalRecords: 0
};
