import React, { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import LoadingScreen from 'src/components/LoadingScreen';
import axios from 'axios';
import urlBuilder from 'src/utils/urlBuilder';
import store from 'src/store';
import User from 'src/models/User';
import { authenicate, getMe, getMyAccountInfo } from 'src/apis/user';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  redirect: string;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string, redirect?: string) => Promise<void>;
  logout: () => void;
  updateUser: (user: User) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    redirect?: string;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type UpdateUserAction = {
  type: 'UPDATE_USER';
  payload: {
    user: User;
  };
};

type Action = InitialiseAction | LoginAction | LogoutAction | UpdateUserAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  redirect: '',
  user: null
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        redirect: '',
        user
      };
    }
    case 'LOGIN': {
      const { user, redirect } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        redirect,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        redirect: '',
        user: null
      };
    }
    case 'UPDATE_USER': {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => {},
  updateUser: () => {}
});

interface IAuthenicationResponse {
  token: string;
  expiration: string;
  email: string;
  id: string;
  userId: number;
  userName: string;
  role: string;
  roleId: number;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email: string, password: string, redirect?: string) => {
    const authenicateUser = await authenicate(email, password);
    setSession(authenicateUser.token);

    const user = await getMyAccountInfo();
    await user.loadAvatar();

    dispatch({
      type: 'LOGIN',
      payload: {
        user: new User({
          ...authenicateUser,
          ...user
        }),
        redirect
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    store.dispatch({ type: 'USER_LOGOUT' });
  };

  const updateUser = async (user: User) => {
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          //let user = await getMe();

          let user = await getMyAccountInfo();
          await user.loadAvatar();

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        updateUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
