import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, Button, Dialog } from '@material-ui/core';

import Register from './Register';
import Crest from '../Crest';
import Footer from '../Footer';
import Terms from 'src/components/Terms';

const RegisterView: FC = () => {
  const [showTerms, setShowTerms] = useState<boolean>(false);

  const handleTermsToggle = () => {
    setShowTerms((prev) => !prev);
  };

  return (
    <Crest>
      <Register onTermsClick={handleTermsToggle} />
      <Box mt={3}>
        <Footer onTermsClick={handleTermsToggle} />
      </Box>
      <Dialog
        open={showTerms}
        onBackdropClick={() => setShowTerms(false)}
        fullWidth
        maxWidth={'md'}
      >
        <Box m={3}>
          <Terms />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowTerms(false)}
          >
            Close
          </Button>
        </Box>
      </Dialog>
    </Crest>
  );
};

export default RegisterView;
