import React from 'react';
import type { FC } from 'react';
import { Typography, Link, makeStyles } from '@material-ui/core';

interface FooterProps {
  [key: string]: any;
}

const useStyles = makeStyles((theme) => ({
  root: {}
}));

const Footer: FC<FooterProps> = ({ onTermsClick, onPrivacyClick }) => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Typography variant="caption">
        {/* <Link onClick={onPrivacyClick} variant="caption" color="primary">
          Privacy
        </Link>{' '} */}
        Privacy © Bureau Veritas All rights reserved.
      </Typography>
      {/* <br />

      <Link onClick={onTermsClick} variant="caption" color="primary">
        Terms &amp; Conditions
      </Link> */}
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
