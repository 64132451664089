import { combineReducers } from '@reduxjs/toolkit';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as clientReducer } from 'src/slices/client';
import { reducer as programReducer } from 'src/slices/program';
import { reducer as siteReducer } from 'src/slices/site';
import { reducer as projectReducer } from 'src/slices/project';
import { reducer as documentReducer } from 'src/slices/document';
import { reducer as lookupReducer } from 'src/slices/lookup';
import { reducer as commentReducer } from 'src/slices/comment';
import { reducer as shareReducer } from 'src/slices/share';
import { reducer as serviceWorkerReducer } from 'src/slices/service-worker';
import { reducer as activityReducer } from 'src/slices/activities';
import { reducer as batchCsvUploadReducer } from 'src/slices/batch-csv-manager';

const appReducer = combineReducers({
  users: userReducer,
  clients: clientReducer,
  programs: programReducer,
  sites: siteReducer,
  projects: projectReducer,
  documents: documentReducer,
  lookups: lookupReducer,
  comments: commentReducer,
  share: shareReducer,
  serviceWorker: serviceWorkerReducer,
  activities: activityReducer,
  batchCsvUpload: batchCsvUploadReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
