import React, { createContext, useContext, useEffect } from 'react';
import type { ReactNode } from 'react';

import { reducer, IState, defaultState, controller } from './@state';
import { IController } from './@state/controller';
import { eventEmitter } from 'src/utils/reducer/middleware/eventEmitter';
import { useReducerMiddleware } from 'src/utils/reducer/middleware/useReducerMiddleware';
interface IContext {
  controller?: IController;
  state?: IState | null;
  ref?: any;
}

const ClientsContext = createContext<IContext>({});

const useClients = (): IContext => {
  const context = useContext(ClientsContext);
  if (context === undefined) {
    throw new Error('useClients must be used within a ClientsProvider');
  }
  return context;
};

interface ProviderProps {
  children: ReactNode;
}

const ClientsProvider: React.FC<ProviderProps> = ({
  children
}: ProviderProps) => {
  const [state, dispatch] = useReducerMiddleware<IState>(
    reducer,
    defaultState,
    [eventEmitter]
  );

  const _controller = controller(dispatch, state);

  return (
    <ClientsContext.Provider value={{ state, controller: _controller }}>
      {children}
    </ClientsContext.Provider>
  );
};

export { ClientsProvider, useClients };
