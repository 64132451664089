import React from 'react';
import { makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';
import type { FC } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  progressBarContainer: {
    height: theme.spacing(2),
    width: '100%',
    backgroundColor: theme.palette.background.dark,
    position: 'relative'
  },
  progressBar: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  }
}));

interface Props {
  [key: string]: any;
  progress: number;
}

const ProgressBar: FC<Props> = ({ progress = 0 }) => {
  const classes = useStyles();

  return (
    <div className={classes.progressBarContainer}>
      <div className={classes.progressBar} style={{ width: `${progress}%` }} />
    </div>
  );
};

export default ProgressBar;
