import React, { useEffect, useState, useCallback } from 'react';
import type { FC } from 'react';

import {
  Box,
  Container,
  Paper,
  makeStyles,
  Typography,
  Button,
  TextField,
  Grid,
  Divider,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Badge,
  Backdrop,
  IconButton
} from '@material-ui/core';

import type { Theme } from 'src/theme';
import MultiUploadTable from 'src/components/Tables/MultiUploadTable';
import { capitalize } from 'src/utils/string';
import ClientAutoComplete from 'src/components/Common/Forms/ClientAutoComplete';
import { queryHelper } from 'src/apis/dynamic-query-helper';
import ProgramAutoComplete from 'src/components/Common/Forms/ProgramAutoComplete';
import { Levels } from 'src/types/Levels';
import useAuth from 'src/hooks/useAuth';
import SiteAutoComplete from 'src/components/Common/Forms/SiteAutoComplete';
import ProjectAutoComplete from 'src/components/Common/Forms/ProjectAutoComplete';
import { IUploadFile } from 'src/types/IUploadFile';
import MessageIcon from '@material-ui/icons/Message';
import { Close, DeleteForever } from '@material-ui/icons';
import { useDispatch, useSelector } from 'src/store';
import useFileUpload from './@hooks/useFileUpload';
import BulkFileUpdateForm from './@components/BulkFileUpdateForm';
import FileIcon from 'src/components/FileIcon';

import useDebounce from 'src/hooks/useDebounce';
import ViewPortClamp from 'src/components/ViewPortClamp';
import { isObjectEmpty } from 'src/utils/object';
import { Alert } from '@material-ui/lab';
import { createUploadFile } from 'src/factory/UploadFile';
import fileSize from 'src/utils/fileSize';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column'
  },
  group: {
    border: `solid ${theme.palette.divider} 1px`,
    borderRadius: 5
  },
  uploadTo: {
    paddingTop: 10,
    paddingRight: 20
  },
  comments: {
    minWidth: '40vw',
    minHeight: '10vw'
  }
}));

interface MultiUploadFormProps {
  onClose?: () => void;
  systemFiles?: File[];
  onSubmit?: () => void;
  document?: any;
}

const MultiUploadForm: FC<MultiUploadFormProps> = ({
  systemFiles = [],
  document,
  onClose,
  onSubmit
}) => {
  const classes = useStyles();
  const { user: loggedInUser } = useAuth();
  const dispatch = useDispatch();
  const {
    files,
    setFiles,
    bulkUpdate,
    updateFile,
    setSystemFiles,
    uploadFiles,
    updateAllFiles,
    isValid
  } = useFileUpload();

  console.log('document', document);
  console.log('files', files);

  // useEffect(() => {
  //   if (systemFiles.length > 0) setSystemFiles(systemFiles);
  // }, [systemFiles]);

  // state
  const [clientId, setClientId] = useState<number>();
  const [clientName, setClientName] = useState<string>(null);

  const [globalFileSettings, setGlobalFileSettings] = useState<
    Partial<IUploadFile>
  >({
    clientId: null,
    programId: null,
    siteId: null,
    projectId: null,
    linkLevel: Levels.CLIENT
  });

  const [selection, setSelection] = useState([]);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [initSelection, setInitSelection] = useState<boolean>(true);

  const [currentFileForComment, setCurrentFileForComment] = useState<
    IUploadFile
  >(null);

  const [currentFileForCommentValue, setCurrentFileForCommentValue] = useState(
    ''
  );

  useEffect(() => {
    if (document) {
      const _level =
        (capitalize(document?.linkLevel) as Levels) || Levels.CLIENT;

      // setClientId(document?.clientID);

      // if (_level !== Levels.CLIENT && _level !== Levels.USER) {
      //   setClientName(document?.clientName);
      // }

      setGlobalFileSettings({
        clientId: document?.clientID ?? null,
        programId: document?.programID ?? null,
        siteId: document?.siteID ?? null,
        projectId: document?.projectID ?? null,
        userId: _level === Levels.USER ? document?.id : null,
        linkLevel: _level,
        clientName:
          document?.clientName &&
          _level !== Levels.CLIENT &&
          _level !== Levels.USER
            ? document?.clientName
            : null
      });
    }
  }, [document]);

  useEffect(() => {
    setSystemFiles(systemFiles);
  }, [systemFiles]);

  const [defaultsSet, setDefaultSet] = useState(false);

  useEffect(() => {
    if (loggedInUser.isExternal && files.length > 0 && !defaultsSet) {
      updateAllFiles({
        active: true,
        assessmentTypeId: '33',
        classificationId: '13',
        statusId: '1'
      });
      setDefaultSet(true);
    }
  }, [loggedInUser, files]);

  useEffect(() => {
    if (files.length > 0 && initSelection) {
      setSelection(files.map((file) => file.id));
      setInitSelection(false);
    }
  }, [files, initSelection]);

  const handleOpenCommentsClick = (event, file) => {
    setCurrentFileForComment(file);
    setCurrentFileForCommentValue(file?.comments ?? '');
    setShowComments(true);
  };

  const handleDeleteClick = (event, row) =>
    setFiles(files.filter((file) => file.id !== row.id));

  const handleCommitChanges = useCallback(
    ({ changed }) => {
      const changedFileId = Object.keys(changed)[0];
      const oldFile = files.find((file) => file.id == changedFileId);
      const newFile = {
        ...oldFile,
        ...changed[changedFileId]
      };

      updateFile(newFile);
    },
    [files]
  );

  const handleSaveCommentClick = () => {
    const updatedFile = {
      ...currentFileForComment,
      comments: currentFileForCommentValue
    };

    updateFile(updatedFile);

    setShowComments(false);
    setCurrentFileForComment(null);
    setCurrentFileForCommentValue('');
  };

  const handleApplyClick = (bulkFileInfo: IUploadFile) => {
    // ref: https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript

    const getSelectedFiles = files.filter((file) =>
      selection.includes(file.id)
    );

    if (loggedInUser.isExternal) {
      bulkFileInfo = {
        ...bulkFileInfo,
        active: true,
        assessmentTypeId: '33',
        classificationId: '13',
        statusId: '1'
      };
    }

    bulkUpdate(bulkFileInfo, getSelectedFiles);
  };

  const handleCloseClick = () => {
    setShowComments(false);
    setCurrentFileForComment(null);
    setCurrentFileForCommentValue('');
  };

  const menuActions = useCallback(
    (row) => {
      return [
        {
          label: 'Remarks',
          onClick: handleOpenCommentsClick,
          icon: row?.comments ? (
            <Badge variant="dot" color="primary">
              <MessageIcon />
            </Badge>
          ) : (
            <MessageIcon />
          )
        },
        {
          label: 'Delete',
          onClick: handleDeleteClick,
          icon: <DeleteForever />
        }
      ];
    },
    [files]
  );

  const handleUploadFilesClick = () => {
    //updateAllFiles(globalFileSettings);

    uploadFiles(globalFileSettings);
  };

  const clearLocations = (level: Levels, data) => {
    // setClientName(null);

    data = {
      ...data
    };

    if (level === Levels.CLIENT || level === Levels.USER) {
      data = {
        ...data,
        programId: null,
        siteId: null,
        projectId: null,
        clientName: null
      };
      // setFieldValue('programID', null);
      // setFieldValue('siteID', null);
      // setFieldValue('projectID', null);
    }

    if (level === Levels.PROGRAM) {
      data = {
        ...data,
        siteId: null,
        projectId: null,
        clientName: null,
        clientId: null
      };
    }

    if (level === Levels.SITE) {
      data = {
        ...data,
        programId: null,
        projectId: null,
        clientName: null,
        clientId: null
      };
      // setFieldValue('programID', null);
      // setFieldValue('projectID', null);
    }

    if (level === Levels.PROJECT) {
      data = {
        ...data,
        programId: null,
        siteId: null,
        clientName: null,
        clientId: null
      };
      // setFieldValue('programID', null);
      // setFieldValue('siteID', null);
    }

    return data;
  };

  const isFormValid = () => {
    console.log('validateForm', validateForm());
    return validateForm().length === 0;
  };

  const validateForm = () => {
    let errors = [];

    if (
      globalFileSettings.linkLevel === Levels.CLIENT &&
      (globalFileSettings.clientId === null ||
        globalFileSettings.clientId === 0)
    ) {
      errors = [...errors, { clientId: 'Client name is required' }];
    }

    if (
      globalFileSettings.linkLevel === Levels.PROGRAM &&
      (globalFileSettings.programId === null ||
        globalFileSettings.programId === 0)
    ) {
      errors = [...errors, { programId: 'Program name is required' }];
    }

    if (
      globalFileSettings.linkLevel === Levels.SITE &&
      (globalFileSettings.siteId === null || globalFileSettings.siteId === 0)
    ) {
      errors = [...errors, { siteId: 'Site name is required' }];
    }

    if (
      globalFileSettings.linkLevel === Levels.PROJECT &&
      (globalFileSettings.projectId === null ||
        globalFileSettings.projectId === 0)
    ) {
      errors = [...errors, { projectId: 'Project name is required' }];
    }

    return errors;
  };

  return (
    <>
      <ViewPortClamp
        stickyComponent={() => (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            m={2}
          >
            <Button
              variant="contained"
              style={{ marginRight: 16 }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={!(isFormValid() && isValid()) || files.length <= 0}
              color="primary"
              variant="contained"
              onClick={handleUploadFilesClick}
            >
              Upload Files
            </Button>
          </Box>
        )}
      >
        <Box p={2} display="flex" flexDirection="row" alignItems="center">
          <Box flex={1}>
            <Typography variant="h3">Upload Multiple Files</Typography>
          </Box>
          {/* {onClose && (
            <Box>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
          )} */}
        </Box>
        <Divider />

        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioGroup
                row
                onChange={(e) => {
                  let update: Partial<IUploadFile> = {
                    linkLevel: e.target.value
                  };
                  //setFieldValue('linkLevel', e.target.value);

                  if (e.target.value === Levels.PROGRAM) {
                    update = clearLocations(Levels.PROGRAM, {
                      ...globalFileSettings,
                      ...update
                    });
                    //setFieldValue('id', globalFileSettings?.programID);
                  }

                  if (e.target.value === Levels.SITE) {
                    update = clearLocations(Levels.SITE, {
                      ...globalFileSettings,
                      ...update
                    });
                    // setFieldValue('id', globalFileSettings?.siteID);
                  }

                  if (e.target.value === Levels.PROJECT) {
                    update = clearLocations(Levels.PROJECT, {
                      ...globalFileSettings,
                      ...update
                    });
                    // setFieldValue('id', globalFileSettings?.projectID);
                  }

                  if (e.target.value === Levels.USER) {
                    update = clearLocations(Levels.USER, {
                      ...globalFileSettings,
                      ...update,
                      userId: loggedInUser.userID
                    });
                    // setFieldValue('id', user.userID);
                  }

                  if (e.target.value === Levels.CLIENT) {
                    update = clearLocations(Levels.CLIENT, {
                      ...globalFileSettings,
                      ...update
                    });
                    // setFieldValue('id', clientId);
                  }

                  setGlobalFileSettings(update);
                }}
                value={globalFileSettings.linkLevel}
              >
                <FormLabel component="legend" className={classes.uploadTo}>
                  Upload to Location:
                </FormLabel>
                <FormControlLabel
                  value={Levels.CLIENT}
                  control={<Radio color="primary" />}
                  label="Client"
                />
                <FormControlLabel
                  value={Levels.PROGRAM}
                  control={<Radio color="primary" />}
                  label="Program"
                />
                <FormControlLabel
                  value={Levels.SITE}
                  control={<Radio color="primary" />}
                  label="Site"
                />
                <FormControlLabel
                  value={Levels.PROJECT}
                  control={<Radio color="primary" />}
                  label="Project"
                />
                {loggedInUser.isAdmin && (
                  <FormControlLabel
                    value={Levels.USER}
                    control={<Radio color="primary" />}
                    label="User"
                  />
                )}
              </RadioGroup>
            </Grid>

            {globalFileSettings.linkLevel === Levels.CLIENT && (
              <Grid item xs={12}>
                <ClientAutoComplete
                  extraQuery={queryHelper.getActiveRecord().getQuery()}
                  required
                  clientId={globalFileSettings.clientId}
                  // onBlur={handleBlur}
                  onChange={(e: object, value: any | null) => {
                    console.log('value?.clientID', value?.clientID);
                    let data = clearLocations(Levels.CLIENT, {
                      ...globalFileSettings,
                      clientId: value?.clientID ?? null,
                      id: value?.clientID ?? null,
                      linkLevel: Levels.CLIENT
                    });

                    setGlobalFileSettings(data);

                    // clearLocations(Levels.CLIENT, setFieldValue);
                    // setFieldValue('clientID', value?.clientID);
                    // setFieldValue('id', value?.clientID);
                    //setClientId(value?.clientID);
                    // setFieldValue('linkLevel', Levels.CLIENT);
                  }}
                  // touched={touched}
                  // errors={errors}
                />
              </Grid>
            )}

            {globalFileSettings.linkLevel === Levels.PROGRAM && (
              <Grid item xs={12}>
                <ProgramAutoComplete
                  extraQuery={queryHelper.getActiveRecord().getQuery()}
                  required
                  programId={globalFileSettings.programId}
                  // onBlur={handleBlur}
                  onChange={(e: object, value: any | null) => {
                    if (!value?.programID) return;

                    let data = clearLocations(Levels.PROGRAM, {
                      ...globalFileSettings,
                      programId: value?.programID,
                      id: value?.programID
                    });

                    setGlobalFileSettings({
                      ...data,
                      clientName: value?.clientName
                    });

                    // setClientName(value?.clientName);

                    // setFieldValue('programID', value?.programID);
                    // setFieldValue('id', value?.programID);v
                  }}
                  // setFieldValue={setFieldValue}
                  // touched={touched}
                  // errors={errors}
                />
              </Grid>
            )}

            {globalFileSettings.linkLevel === Levels.SITE && (
              <Grid item xs={12}>
                <SiteAutoComplete
                  extraQuery={queryHelper
                    .getActiveRecord()

                    .getQuery()}
                  required
                  siteId={globalFileSettings.siteId}
                  // onBlur={handleBlur}
                  onChange={(e: object, value: any | null) => {
                    let data = clearLocations(Levels.SITE, {
                      ...globalFileSettings,
                      siteId: value?.siteID,
                      id: value?.siteID
                    });

                    setGlobalFileSettings({
                      ...data,
                      clientName: value?.clientName
                    });

                    // setClientName(value?.clientName);

                    // clearLocations(Levels.SITE, setFieldValue);
                    // setFieldValue('siteID', value?.siteID);
                    // setFieldValue('id', value?.siteID);
                    // setClientName(value?.clientName);
                  }}
                  // setFieldValue={setFieldValue}
                  // touched={touched}
                  // errors={errors}
                />
              </Grid>
            )}
            {globalFileSettings.linkLevel === Levels.PROJECT && (
              <Grid item xs={12}>
                <ProjectAutoComplete
                  extraQuery={queryHelper.getActiveRecord().getQuery()}
                  required
                  projectId={globalFileSettings.projectId}
                  // onBlur={handleBlur}
                  onChange={(e: object, value: any | null) => {
                    let data = clearLocations(Levels.PROJECT, {
                      ...globalFileSettings,
                      projectId: value?.projectID,
                      id: value?.projectID
                    });

                    setGlobalFileSettings({
                      ...data,
                      clientName: value?.clientName
                    });

                    // clearLocations(Levels.PROJECT, setFieldValue);
                    // setFieldValue('projectID', value?.projectID);
                    // setFieldValue('id', value?.projectID);
                    // setClientName(value?.clientName);
                  }}
                  // setFieldValue={setFieldValue}
                  // touched={touched}
                  // errors={errors}
                />
              </Grid>
            )}

            {globalFileSettings?.clientName && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Client Name"
                  value={globalFileSettings?.clientName}
                  variant="outlined"
                  disabled
                />
              </Grid>
            )}

            {validateForm().length > 0 && (
              <Box mb={2} style={{ width: '100%' }}>
                <Alert severity="error" style={{ paddingTop: 0 }}>
                  <Typography variant="h6">Please note</Typography>

                  <ul style={{ fontSize: 10 }}>
                    {validateForm().map((validation) => {
                      const [key, value] = Object.entries(validation)[0];

                      return (
                        <li key={key}>
                          {JSON.stringify(value)?.replaceAll('"', '')}
                        </li>
                      );
                    })}
                  </ul>
                </Alert>
              </Box>
            )}
          </Grid>
        </Box>
        <Box p={2} mx={2} className={classes.group}>
          <BulkFileUpdateForm
            onApplyClick={handleApplyClick}
            isDisabled={selection.length <= 0}
          />
        </Box>
        <MultiUploadTable
          rows={files}
          selection={selection}
          setSelection={setSelection}
          onCommitChanges={handleCommitChanges}
          menuActions={menuActions}
        />
        <Box m={2}>
          <Typography variant="body2">
            Please note the maximum size per file is 100MB
          </Typography>
        </Box>
      </ViewPortClamp>

      <Box m={4} />

      <Backdrop
        className={classes.backdrop}
        open={showComments}
        onClick={() => {}}
      >
        <Paper className={classes.comments}>
          <Box display="flex" flexDirection="row" alignItems="center" m={2}>
            <FileIcon
              file={currentFileForComment?.filename ?? ''}
              width={48}
              height={48}
            />
            <Box px={2} flex={1}>
              <Typography variant="h4">
                {currentFileForComment?.filename ?? 0}
              </Typography>
              <Typography variant="h4">
                {fileSize(currentFileForComment?.fileSize ?? 0, true)}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleCloseClick}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box m={2}>
            <TextField
              multiline
              fullWidth
              rows={3}
              label="Remarks"
              variant="outlined"
              value={currentFileForCommentValue}
              onChange={(event) =>
                setCurrentFileForCommentValue(event.target.value)
              }
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            m={2}
          >
            <Button
              variant="contained"
              style={{ marginRight: 16 }}
              onClick={handleCloseClick}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSaveCommentClick}
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Backdrop>
    </>
  );
};

export default MultiUploadForm;
