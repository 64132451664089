import React, { useState } from 'react';
import type { FC } from 'react';
import type { Theme } from 'src/theme';
import { makeStyles, Backdrop } from '@material-ui/core';
import { useDispatch, useSelector } from 'src/store';
import { closeModal } from 'src/slices/share';
import ShareForm from './ShareForm';
import * as api from 'src/apis/share';
import { pluralize } from 'src/utils/string';
import { submitError, submitSuccess } from 'src/components/Forms/FormikHelper';
import {
  doesUserHaveAccessToIds,
  getUserAccessTypeToLevel
} from 'src/apis/user';
import { Levels } from 'src/types/Levels';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  card: {
    width: '50%',
    maxHeight: '75vh',
    overflow: 'auto',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none'
  }
}));

export interface Props {
  [key: string]: any;
}

const ShareManager: FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { shareObject, shareType, isModalOpen } = useSelector(
    (state) => state.share
  );

  const getLevelId = (shareType, shareObject): string => {
    let shareIds = '';

    if (shareType == 'Client') {
      shareIds = shareObject?.clientID;
    }

    if (shareType == 'Site') {
      shareIds = shareObject?.siteID;
    }

    if (shareType == 'Program') {
      shareIds = shareObject?.programID;
    }

    if (shareType == 'Project') {
      shareIds = shareObject?.projectID;
    }

    if (shareType == 'Document') {
      shareIds = shareObject?.documentID;
    }

    if (shareType == 'Documents') {
      shareIds = shareObject.join();
      // shareIds = shareObject
      //   .split(',')
      //   .filter((x) => x && !isNaN(x))
      //   .map((x) => parseInt(x));
    }

    return shareIds;
  };

  // state
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleClose = () => {
    dispatch(closeModal());
    setSelectedUsers([]);
  };

  const handleAddUser = async (e, user) => {
    if (shareType === 'Documents') {
      try {
        await doesUserHaveAccessToIds(
          user.userID,
          'Document',
          getLevelId(shareType, shareObject)
        );

        setSelectedUsers((prev) => [user, ...prev]);
      } catch (err) {
        user.accessDenied = true;
        setSelectedUsers((prev) => [user, ...prev]);
      }
    } else {
      try {
        await doesUserHaveAccessToIds(
          user.userID,
          shareType === 'Documents' ? Levels.DOCUMENT : shareType,
          getLevelId(shareType, shareObject)
        );

        setSelectedUsers((prev) => [user, ...prev]);
      } catch (err) {
        submitError(err);
      }
    }
  };

  const handleSendInvite = async () =>
    // values,
    // actions
    {
      try {
        //   const response = await addDocument(
        //     values.level,
        //     values.id,
        //     values as IDocument
        //   );

        //   await postDocument(response?.data?.documentID, currentFile);

        if (selectedUsers.length === 0) return;

        let sharedLink,
          sharedLinkSimplifiedView = '';

        let shareIds = 0;

        if (shareType == 'Client') {
          sharedLink = `app/clients/edit/${shareObject?.clientID}`;
          sharedLinkSimplifiedView = `app/clients/edit/${shareObject?.clientID}`;
          shareIds = shareObject?.clientID;
        }

        if (shareType == 'Site') {
          sharedLink = `app/sites/edit/${shareObject?.siteID}`;
          sharedLinkSimplifiedView = `app/sites/edit/${shareObject?.siteID}`;
          shareIds = shareObject?.siteID;
        }

        if (shareType == 'Program') {
          sharedLink = `app/programs/edit/${shareObject?.programID}`;
          sharedLinkSimplifiedView = `app/programs/edit/${shareObject?.programID}`;
          shareIds = shareObject?.programID;
        }

        if (shareType == 'Project') {
          sharedLink = `app/projects/edit/${shareObject?.projectID}`;
          sharedLinkSimplifiedView = `app/projects/edit/${shareObject?.projectID}`;
          shareIds = shareObject?.projectID;
        }

        if (shareType == 'Document') {
          sharedLink = `app/files/download/?ids=${shareObject?.documentID}`;
          sharedLinkSimplifiedView = `app/files/download/?ids=${shareObject?.documentID}`;
          shareIds = shareObject?.documentID;
        }

        if (shareType == 'Documents') {
          sharedLink = `app/files/download/?ids=${shareObject}`;
          sharedLinkSimplifiedView = `app/files/download/?ids=${shareObject}`;
          shareIds = shareObject.join();
        }

        const shareObj = {
          emailAddresses: selectedUsers.map((x) => x.email),
          sharedLink,
          sharedType: shareType === 'Documents' ? 'Document' : shareType,
          sharedIds: `${shareIds}`,
          sharedLinkSimplifiedView
        };

        api.sendShare(shareObj);

        submitSuccess(pluralize(selectedUsers.length, 'Sent Invite to User'));

        handleClose();
      } catch (err) {
        submitError(err);
      }
    };

  if (!isModalOpen) return null;

  return (
    <Backdrop className={classes.backdrop} open onClick={() => {}}>
      <div onClick={(e) => e.stopPropagation()} className={classes.card}>
        <ShareForm
          shareObject={shareObject}
          shareType={shareType}
          selectedUsers={selectedUsers}
          onAddUser={handleAddUser}
          onRemove={(e, user) =>
            setSelectedUsers((prev) =>
              prev.filter((_user) => {
                if (_user.userID !== user.userID) return _user;
              })
            )
          }
          onSendInvite={handleSendInvite}
          onClose={handleClose}
          onCancel={handleClose}
        />
      </div>
    </Backdrop>
  );
};

ShareManager.propTypes = {};

export default ShareManager;
